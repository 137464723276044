import { Input, Modal } from "antd";
import DeleteModal from "../ui/Modals/DeleteModal";

const FeedModals = ({
  deleteColumn,
  setNewTitle,
  tableid,
  fetchData,
  fetchFeedColumns,
  currColumn,
  newTitle,
  deleteModalVisible,
  editModalVisible,
  setDeleteModalVisible,
  setEditModalVisible,
  renameColumn,
  updateData
}) => {
  const handleEditModalCancel = (e) => {
    e.stopPropagation();
    setEditModalVisible(false);
  };
 
  const handleDeleteModalOk = async (e) => {
    e.stopPropagation();
    setDeleteModalVisible(false);
    await deleteColumn(currColumn)
  };
  const handleEditModalOk = async (e) => {
    e.stopPropagation();
    // updateColumn(id,newTitle)
    await renameColumn(tableid, currColumn, newTitle);
    // fetchFeedColumns();
    updateData();
    setEditModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Редактировать"
        open={editModalVisible}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
      >
        <Input
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          onClick={(e) => e.stopPropagation()} // Предотвращение всплытия при клике на поле ввода
        />
      </Modal>{" "}
      
      <DeleteModal
        onConfirmDelete={handleDeleteModalOk}
        isModalDeleteOpen={deleteModalVisible}
        setIsModalDeleteOpen={setDeleteModalVisible}
        title="Удалить столбец"
        text={`Вы уверены, что хотите удалить колонку со всем содержимым?`}
      />
    </>
  );
};
export default FeedModals;

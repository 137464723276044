import React, { useContext, useState } from "react";

import { Avatar, Layout, Space } from "antd";
import { Context } from "../../index";
import { CSSTransition } from "react-transition-group";
import { Header } from "antd/es/layout/layout";
import { toJS } from "mobx";
import { Flex } from "antd";
import { Link, Outlet } from "react-router-dom";

import SidebarMenuNew from "./Sidebar/SidebarMenuNew";
import SiderTrigger from "./Sidebar/SiderTrigger";
const { Content, Footer, Sider } = Layout;

const MainLayout = ({
  children,
  openKeys,
  handleOpenChange,
  setOpenMenuItem,
  openMenuItem,
}) => {
  const { store } = useContext(Context);
  const [collapsed, setCollapsed] = useState(false);

  const headerStyle = {
    color: "#000",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff",
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={headerStyle}>
        <Flex
          justify="space-between"
          style={{ alignItems: "center", width: "100%" }}
        >
          <div className="logo logo-layout">
            <a href="/" style={{ display: "flex" }}>
              {" "}
              <img src="/logo.png" alt="" />
            </a>
          </div>
          <Space gap="large">
            <div>
              <Avatar
                style={{
                  backgroundColor: "rgba(74, 94, 255, 1)",
                  verticalAlign: "middle",
                  marginRight: 10,
                }}
              >
                {toJS(store.user.username)[0].toUpperCase()}
              </Avatar>
              {toJS(store.user.email)}
            </div>

            <Link
              style={{
                color: "rgba(17, 20, 47, 1)",
                cursor: "pointer",
                marginLeft: 40,
              }}
              to="/logout"
            >
              <span>Выйти</span>
            </Link>
          </Space>
        </Flex>
      </Header>

      <Layout>
        <Sider
          collapsible
          theme="light"
          style={{
            backgroundColor: "rgba(250, 250, 250, 1)",
            overflow: "auto",
            //height: "100vh",
            position: "fixed",
            left: 0,
            top: 64,
            bottom: 0,
          }}
          width={300}
          trigger={<SiderTrigger collapsed={collapsed} />}
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <CSSTransition
            in={!collapsed}
            timeout={300}
            classNames="sidebar-animation"
            unmountOnExit
          >
            <div className="SidebarMenuNew">
              <SidebarMenuNew
                openKeys={openKeys}
                handleOpenChange={handleOpenChange}
                setOpenMenuItem={setOpenMenuItem}
                openMenuItem={openMenuItem}
              />
            </div>
          </CSSTransition>
        </Sider>
        <Content
          style={{
            marginLeft: collapsed?80:300,
              backgroundColor: "#F7F6F6",
          }}
        >
          <div
            style={{
              // minHeight: '100vh',
              height: "100%",
              display: "flex",

              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <div style={{ padding: 24, flexGrow: 1 }}>
              {" "}
              <Outlet />
            </div>
            <Footer
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "26px 37px 26px",
              }}
            >
              <div className="">Центр фидов ©{new Date().getFullYear()}</div>
              <div className="">Политика конфиденциальности</div>
            </Footer>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;

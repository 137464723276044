import {Statistic } from "antd";
import { useEffect, useState } from "react";

const FeedDashboardOverviewStat = ({ value, desc,precision,showall }) => {
  // const formatter = (value) => <CountUp end={value} separator="," />;

  const [suf, setSuf] = useState('');
  const [val, setVal] = useState(0);

  useEffect(() => {
    if(!showall){

      if(value > 900000){
        setSuf('млн.');
        setVal(value / 1000000)
      }
      else if(value > 900){
        setSuf('тыс.');
        setVal(value / 1000)
      }
      else{
        setSuf('');
        setVal(value)
      }
    }
    else{
      setSuf('');
      setVal(value);
    }
  }, [value])
  


  return (
    
              <div style={{width: '100%'}}>
                  <Statistic valueStyle={{color: "#4A5EFF", fontSize: 20}} precision={precision}  suffix={suf} value={val}  />
                
                  <div className="feeddashboardcard-content_statistic">{desc}</div>
              </div>
              

  );
};
export default FeedDashboardOverviewStat;

import React from "react";
import {  Input, Select } from "antd";

const AddProjectTableMainFields = ({
  handleSelectChange,
  modeVals,
  selectValue,
  handleNameChange,
  projectName,
  selectedFeedType,
  setSelectedFeedType,
  feedTypes
}) => {
  return (
    <div style={{width: '100%',margin: '20px 0'  }}>
      <div >
        <Input
          value={projectName}
          style={{width: '100%',marginBottom: 20  }}
          placeholder="Название"
          onChange={(value) => handleNameChange(value)}
        />
      </div>
      <div>
        <Select
          size="100px"
          placeholder="Выберете режим"
          value={selectValue || null}
          onChange={(value) => handleSelectChange(value)}
          style={{ width: '100%',height: '40px',marginBottom: 20 }}
          options={modeVals.length > 0 ? modeVals : null}
        />
      </div>
      <div>
        <Select
          size="100px"
          placeholder="Выберете тип фида"
          value={selectedFeedType || null}
          onChange={(value) => setSelectedFeedType(value)}
          style={{ width: '100%',height: '40px' }}
          options={feedTypes.length > 0 ? feedTypes : null}
        />
      </div>
    </div>
  );
};

export default AddProjectTableMainFields;

const FilterTableRowErr = ({ id, field, errorsList }) => {
  const error = errorsList.find((el) => el.id === id && el.field === field);

  if (!error) return null;

  return (
    <div className="error" role="alert">
      {error.error}
    </div>
  );
};

export default FilterTableRowErr;

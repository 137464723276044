import { Input, Select, Flex, TreeSelect } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import FilterTableRowErr from "./FilterTableRowErr";
const { SHOW_PARENT } = TreeSelect;

const filterMethodValsText = [
  { label: "Равно", value: "is equal" },
  { label: "Не равно", value: "is not equal" },
  { label: "Содержит", value: "is like" },
  { label: "Не содержит", value: "is not like" },
  { label: "Пустое", value: "is blank" },
  { label: "Не пусто", value: "is not blank" },
];
const filterMethodValsNumber = [
  { label: "Равно", value: "=" },
  { label: "Не равно", value: "!=" },
  { label: "Меньше", value: "<" },
  { label: "Больше", value: ">" },
  { label: "Меньше или равно", value: "<=" },
  { label: "Больше или равно", value: ">=" },
  { label: "Пустое", value: "is blank" },
  { label: "Не пусто", value: "is not blank" },
];

const FilterTableRow = ({
  id,
  filters,
  removeFilter,
  columns,
  changeFilter,
  errorsList,
}) => {
  const [selectedColumn, setSelectedColumn] = useState({});
  const [selectedMethod, setSelectedMethod] = useState("");
  const [categoriesTransformed, setCategoriesTransformed] = useState({});

  function transformData(data) {
    return data.map((item) => ({
      ...item,
      value: item.id, // Добавляем поле value, равное id
      title: item.title, // Убеждаемся, что поле title остается неизменным
      children: item.children ? transformData(item.children) : undefined, // Рекурсивно обрабатываем дочерние элементы
    }));
  }

  const handleSelectColumnChange = (value) => {
    const selectedColumnNew = columns.find((column) => column.value === value);

    if (selectedColumnNew.columntype !== selectedColumn.columntype) {
      changeFilter(id, null, "filtermethod", selectedColumn.columntype);
      changeFilter(id, null, "filtervalue", selectedColumn.columntype);
    }

    if (selectedColumnNew.columntype === "category" && selectedColumnNew.metadata) {
      setCategoriesTransformed(transformData(selectedColumnNew.metadata));
    }

    setSelectedColumn(selectedColumnNew);
    changeFilter(id, value, "filtercolumn", selectedColumnNew.columntype);
  };

  const handleSelectMethodChange = (value) => {
    setSelectedMethod(value);
    changeFilter(id, value, "filtermethod", selectedColumn.columntype);
  };

  const handleCategoryChange = (value) => {
    changeFilter(id, "in", "filtermethod", selectedColumn.columntype);
    changeFilter(id, null, "filtervalue", selectedColumn.columntype);
    changeFilter(id, value, "filtervalue", selectedColumn.columntype);
  };

  const handleInputValueChange = (e) => {
    changeFilter(id, e.target.value, "filtervalue", selectedColumn.columntype);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    const currentFilter = filters.find((filter) => filter.id === id);
    setSelectedMethod(currentFilter?.filtermethod || "");
  }, [filters, id]);

  return (
    <Flex gap={14} justify="space-between" align="baseline">
      <Flex gap={14} align="flex-start">
        <div className="filtertable_row">
          <Select
            placeholder="Выберите поле"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            style={{ width: 162 }}
            disabled={filters.find((filter) => filter.id === id).disabled}
            value={filters.find((filter) => filter.id === id).filtercolumn || null}
            className="select-small"
            options={columns}
            onChange={handleSelectColumnChange}
          />
          <FilterTableRowErr field="filtercolumn" id={id} errorsList={errorsList} />
        </div>
        {selectedColumn.columntype !== "category" && (
          <>
            <div className="filtertable_row">
              <Select
                placeholder="Выберите метод"
                style={{ width: 196 }}
                className="select-small"
                disabled={filters.find((filter) => filter.id === id).disabled}
                value={selectedMethod}
                onChange={handleSelectMethodChange}
                options={
                  selectedColumn.columntype === "number"
                    ? filterMethodValsNumber
                    : filterMethodValsText
                }
              />
              <FilterTableRowErr field="filtermethod" id={id} errorsList={errorsList} />
            </div>
            {selectedMethod !== "is blank" && selectedMethod !== "is not blank" && (
              <div className="filtertable_row">
                <Input
                  onChange={handleInputValueChange}
                  placeholder="Значение"
                  disabled={filters.find((filter) => filter.id === id).disabled}
                  value={filters.find((filter) => filter.id === id).filtervalue || null}
                  style={{ width: "100%" }}
                  className="input-small"
                />
                <FilterTableRowErr field="filtervalue" id={id} errorsList={errorsList} />
              </div>
            )}
          </>
        )}
        {selectedColumn.columntype === "category" && (
          <div className="filtertable_row">
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              treeCheckable={true}
              showCheckedStrategy={SHOW_PARENT}
              disabled={filters.find((filter) => filter.id === id).disabled}
              placeholder="Выберите категорию"
              value={filters.find((filter) => filter.id === id).filtervalue || []}
              treeData={categoriesTransformed}
              style={{
                minWidth: 400,
                maxWidth: 800,
              }}
              onChange={handleCategoryChange}
              className="input-small"
            />
            <FilterTableRowErr field="filtervalue" id={id} errorsList={errorsList} />
          </div>
        )}
      </Flex>
      <DeleteOutlined
        onClick={() => {
          removeFilter(id);
        }}
      />
    </Flex>
  );
};

export default FilterTableRow;

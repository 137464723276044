import { Link } from "react-router-dom";
import { Button,  Flex, Form, Input } from "antd";
import { useContext, useState } from "react";
import Logo from "../components/Layout/Header/Logo";
import { Context } from "../index";

const onFinishFailed = (errorInfo) => {
  console.error("Failed:", errorInfo);
};

const Register = () => {
  // const navigate = useNavigate();ё
  const { store } = useContext(Context);
  const [errMessage, setErrMessage] = useState("");
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const handleRegistration = (fields) => {
    store
      .registration(
        fields.username,
        fields.email,
        fields.password,
        fields.confirm_password
      )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setIsShowConfirm(true);
        } else {
          if (response.status === 422) {
            setErrMessage(response.data.detail);
          }
        }
      })
      .catch((error) => {
        console.error("Error during registration:", error);
      });
  };

  return (
    <div
      style={{
        background: "#F1F1F2",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Flex gap="middle" align="center" justify="center" vertical>
        <Logo />
        <div className="authcard">
          {isShowConfirm ? (
            <>
            <h2>Благодарим за регистрацию!</h2>
            <div className="attention">

                <div className="icon">
                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.0001 38.1338C18.707 38.1372 17.436 37.7979 16.3167 37.1504L7.15007 31.8671C6.03634 31.2183 5.11187 30.2892 4.4686 29.1722C3.82533 28.0553 3.48567 26.7894 3.4834 25.5004V14.9004C3.48567 13.6115 3.82533 12.3456 4.4686 11.2286C5.11187 10.1117 6.03634 9.1826 7.15007 8.53377L16.3167 3.25044C17.4366 2.60387 18.7069 2.26349 20.0001 2.26349C21.2932 2.26349 22.5635 2.60387 23.6834 3.25044L32.8501 8.53377C33.9638 9.1826 34.8883 10.1117 35.5315 11.2286C36.1748 12.3456 36.5145 13.6115 36.5167 14.9004V25.5004C36.5145 26.7894 36.1748 28.0553 35.5315 29.1722C34.8883 30.2892 33.9638 31.2183 32.8501 31.8671L23.6834 37.1504C22.565 37.8002 21.2935 38.1396 20.0001 38.1338ZM20.0001 4.80044C19.1482 4.80082 18.3107 5.01883 17.5667 5.43377L8.3334 10.7004C7.61122 11.1367 7.01487 11.7533 6.60289 12.4896C6.1909 13.2259 5.97742 14.0567 5.9834 14.9004V25.5004C5.97742 26.3442 6.1909 27.1749 6.60289 27.9113C7.01487 28.6476 7.61122 29.2641 8.3334 29.7004L17.5001 35.0004C18.2441 35.4149 19.0817 35.6325 19.9334 35.6325C20.7851 35.6325 21.6227 35.4149 22.3667 35.0004L31.6667 29.7004C32.4013 29.2724 33.011 28.6595 33.435 27.9226C33.859 27.1857 34.0825 26.3506 34.0834 25.5004V14.9004C34.0825 14.0503 33.859 13.2152 33.435 12.4783C33.011 11.7414 32.4013 11.1284 31.6667 10.7004L22.5001 5.40043C21.7365 4.97434 20.8744 4.75597 20.0001 4.7671V4.80044Z" fill="#4A5EFF"/>
<path d="M20.0002 22.5338C20.4422 22.5338 20.8661 22.3582 21.1787 22.0456C21.4912 21.733 21.6668 21.3091 21.6668 20.8671V13.1004C21.6668 12.6584 21.4912 12.2345 21.1787 11.9219C20.8661 11.6094 20.4422 11.4338 20.0002 11.4338C19.5581 11.4338 19.1342 11.6094 18.8217 11.9219C18.5091 12.2345 18.3335 12.6584 18.3335 13.1004V20.8671C18.3335 21.3091 18.5091 21.733 18.8217 22.0456C19.1342 22.3582 19.5581 22.5338 20.0002 22.5338Z" fill="#4A5EFF"/>
<path d="M19.9998 28.9671C21.1504 28.9671 22.0832 28.0344 22.0832 26.8838C22.0832 25.7332 21.1504 24.8004 19.9998 24.8004C18.8492 24.8004 17.9165 25.7332 17.9165 26.8838C17.9165 28.0344 18.8492 28.9671 19.9998 28.9671Z" fill="#4A5EFF"/>
</svg>

                </div>
                Мы отправили письмо на указанный адрес электронной почты, для завершения регистрации перейдите по ссылке из письма
               
            </div>
            <Button onClick={()=>{setIsShowConfirm(false)}} size="large" block type="primary" htmlType="submit">
                    Хорошо
                  </Button>
            </>
          ) : (
            <>
             <h2>Регистрация</h2>
              {errMessage && <div style={{ color: "red" }}>{errMessage}</div>}
              <Form
                name="basic"
                layout="vertical"
                style={{
                  maxWidth: 600,
                }}
                onFinish={(fields) => handleRegistration(fields)}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Ваш логин"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста укажите логин",
                    },
                  ]}
                >
                  <Input placeholder="Логин" />
                </Form.Item>
                <Form.Item
                  label="Ваш E-mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста укажите email",
                    },
                    {
                      type: "email",
                      message: "Пожалуйста укажите корректный email",
                    },
                  ]}
                >
                  <Input placeholder="Ваша почта" />
                </Form.Item>
                <Form.Item
                  label="Ваш пароль"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Укажите пароль",
                    },
                  ]}
                >
                  <Input.Password placeholder="Пароль" />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  label="Подтвердите пароль"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Подтвердите пароль",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Пароли должны совпадать")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Введите пароль" />
                </Form.Item>
                <Form.Item>
                  <Button size="large" block type="primary" htmlType="submit">
                    Зарегистрироваться
                  </Button>
                </Form.Item>
              </Form>
              <div className="loginform-regtext">
                Уже зарегистрированны? <Link to="/login">Войдите</Link>
              </div>
            </>
          )}
        </div>
      </Flex>
    </div>
  );
};

export default Register;

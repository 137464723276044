import React, { useState } from "react";
import { PlusOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Popover, Badge, Flex } from "antd";
import FilterTableRow from "./FilterTableRow";
import { v4 as uuidv4 } from "uuid";
const FilterTable = ({
  columns,
  handleFilter,
  filters,
  setFilters,
  removeFilter,
  removeAllFilters,
  filterslen,
}) => {
  // const [filters, setFilters] = useState([]);
  const [errorsList, setErrorsList] = useState([]);

  const addFilter = () => {
    const newFilter = {
      id: uuidv4(),
      // Другие свойства фильтра
    };
    setFilters((prev) => {
      return [...prev, newFilter];
    });
  };
  const [showPopover, setShowPopover] = useState(false);

  const handlePopoverClose = (state) => {
    setShowPopover(state);
  };
  const showFilters = () => {
    setShowPopover(true);
  };
  const clearFilters = async (e) => {
    e.stopPropagation();
    removeAllFilters();
  };

  const changeFilter = (filterid, filtervalue, name, type) => {
    setErrorsList([]);
    setFilters((prev) => {
      return prev.map((item) => {
        if (item.id === filterid) {
          item[name] = filtervalue;
          item.type = type;
        }
        return item;
      });
    });
  };
  const filterTable = () => {
    console.log("filterTable");
    const errors = checkFilters();
    if (!errors.length) {
      handleFilter(filters);
      setShowPopover(false);
    } else {
      setErrorsList(errors);
    }
  };
  const checkFilters = () => {
    let errors = [];
    filters.forEach((filter, index) => {
      if (!filter.type) {
        errors.push({
          id: filter.id,
          field: "filtercolumn",
          error: "Не указано поле",
        });
      } else {
        switch (filter.type) {
          case "category":
            if (!filter.filtervalue || !filter.filtervalue.length) {
              errors.push({
                id: filter.id,
                field: "filtervalue",
                error: "Не указаны значения",
              });
            }
            break;
          case "image":
          case "text":
          case "url":
            if (!filter.filtervalue) {
              // console.log('filtervalue', filter);
              if (
                filter.filtermethod === "is blank" ||
                filter.filtermethod === "is not blank"
              ) {
                console.log("filtervalue", filter.filtermethod);
              
              }
              else{
                errors.push({
                  id: filter.id,
                  field: "filtervalue",
                  error: "Не указано значение",
                });
              }
            }
            if (!filter.filtermethod) {
              errors.push({
                id: filter.id,
                field: "filtermethod",
                error: "Не указан метод",
              });
            }
            break;
          default:
            break;
        }
      }
    });
    return errors;
  };

  return (
    <>
      <Popover
        content={
          <>
            {errorsList.length > 0 && (
              <div className="error">
                Количество полей содержащих ошибки: {errorsList.length}
              </div>
            )}

            {filters.map((el, index) => {
              return (
                <FilterTableRow
                  filters={filters}
                  key={el.id}
                  changeFilter={changeFilter}
                  id={el.id}
                  removeFilter={removeFilter}
                  columns={columns}
                  errorsList={errorsList}
                />
              );
            })}

            <Flex justify="space-between" align="center">
              <div onClick={() => addFilter()} className="btnline">
                <PlusOutlined style={{ fontSize: 11 }} />
                <div className="text">Добавить фильтр</div>
              </div>
              {filters.length > 0 && (
                <Button
                  style={{ marginRight: 28, marginBottom: 0 }}
                  type="primary"
                  onClick={filterTable}
                >
                  Фильтровать
                </Button>
              )}
            </Flex>
            {filters.length === 0 && (
              <span style={{ fontSize: 13, color: "#7A7A7A" }}>
                Фильтры не добавлены
              </span>
            )}
          </>
        }
        placement="bottomLeft"
        trigger="click"
        open={showPopover}
        onOpenChange={handlePopoverClose}
      >
        <Flex gap={20} align="flex-end">
          <Badge count={filterslen} color="#4A5EFF">
            <Button
              icon={<FilterOutlined style={{ fontSize: 18 }} />}
              type="primary"
              className="btn"
              onClick={() => {
                showFilters(true);
              }}
            >
              <span className="text">Фильтр</span>
            </Button>
          </Badge>
          {filterslen > 0 && (
            <div
              onClick={clearFilters}
              style={{
                textDecoration: "underline",
                color: "#A3A3A3",
                cursor: "pointer",
              }}
            >
              Очистить фильтры
            </div>
          )}
        </Flex>
      </Popover>
      {/* <Typography>
        <pre>{JSON.stringify(filters, null, 2)}</pre>
      </Typography>
      <Typography>
        <pre>{JSON.stringify(errorsList, null, 2)}</pre>
      </Typography> */}
    </>
  );
};

export default FilterTable;

import { useContext, useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import FeedDashboardErrors from "../components/Feed/FeedDashboardErrors";
import { Context } from "..";



const FeedDashboardErrorPage = ({handleOpenChange,setOpenMenuItem}) => {

  const { store } = useContext(Context);
  const [feed, setFeed] = useState();
  const [feedErr, setFeedErr] = useState();
  const [dashboardStats, setDashboardStats] = useState();
  const prepareFeedErr = (data)=>{
    if(data){
      return [
        {
          key: "1",
          name: "CategoryId без офферов",
          errs: `${data.without_offer.count} из ${data.without_offer.from}`,
          status: data.without_offer.is_error
        },
        {
          key: "2",
          name: "Количество офферов с 404 страницей",
          errs: `${data.count_404.count} из ${data.count_404.from}`,
          status: data.count_404.is_error
        },
        {
          key: "3",
          name: "Количество офферов с UTM-метками",
          errs: `${data.count_utm.count} из ${data.count_utm.from}`,
          status: data.count_utm.is_error
        },
      
        {
          key: "4",
          name: "Количество офферов-дубликатов",
          errs: `${data.duplicates.count} из ${data.duplicates.from}`,
          status: data.duplicates.is_error
        },
        {
          key: "5",
          name: "Наличие тега oldprice",
          errs: data.has_oldprice?'Нет':'Да',
          status: null
        },
        {
          key: "6",
          name: "Офферы с неизвестным CategoryID",
          errs: `${data.unknown_categories.count} из ${data.unknown_categories.from}`,
          status:  data.unknown_categories.is_error
        },
      ]
    }
    else{
      return [];
    }
  }

  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchFeed = async () => {
      try {
        await store.getFeed(id).then((feed) => {
          if (!feed) {
            navigate("/404");
          }
          handleOpenChange([`parent_${feed.data.project}`,`table_${feed.data.id}`,feed.data.parent?`table_${feed.data.parent}`:null]);

          setOpenMenuItem([`table_${feed.data.id}`])
          setFeed(feed.data);
        });
        await store.getFeedErrors(id).then((feedErrors)=>{
          console.log(feedErrors)
          setFeedErr(prepareFeedErr(feedErrors.data));
          setDashboardStats({
            ...feedErrors.data,
           tag_stats: null,
           nested_tags: null
          })
        })
      } catch (error) {
        console.error("Error fetching feed:", error);
      }
    };
    fetchFeed();
    // eslint-disable-next-line
  }, [id]);

  const handleRemoveFeed = () => {
    store.removeFeed(id);
    navigate("/");
  };


  return (
   
    <> {feed && feed.processing_status === 3 && (
      <FeedDashboardErrors feed={feed} feedErr={feedErr} dashboard={dashboardStats} handleRemoveFeed={handleRemoveFeed} />
      )}</>
     
      
  
  );
};

export default FeedDashboardErrorPage;

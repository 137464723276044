import React, {useState} from 'react';
import { Breadcrumb, Row, Col, Button, Spin } from "antd";
import {DownOutlined, HomeOutlined, LoadingOutlined, UpOutlined} from "@ant-design/icons";
import { Link } from "react-router-dom";
import FeedDashboardOverviewStat from "./FeedDashboardOverviewStat";
import FeedDashboardOverviewTop from "./FeedDashboardOverviewTop";
import {Collapse} from "react-collapse/lib/Collapse";

const FeedDashboardOverview = ({ feed, handleRemoveFeed, dashboard, dashboardUpdate, id, loading, updateDashboardUntilDone }) => {

  const [isOpenedIndicators, setIsOpenedIndicators] = useState(true);

  const antIcon = (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <LoadingOutlined
        style={{
          fontSize: 48,
        }}
        spin
      />
      <div style={{color: '#999', width: 280, fontSize: 15}}>Обновление данных, подождите...</div>
    </div>
  );

  const handleDashboardUpdate = async () => {
    updateDashboardUntilDone(id);
  }

    const handleIsOpenedIndicators = () => {
        setIsOpenedIndicators(!isOpenedIndicators);
    };

  return (
    <div>
        <div className="collapse-section-breadcrumb">
          <Row>
            <Col span={10}>
              <Breadcrumb
                items={[
                  {
                    title: <HomeOutlined />,
                  },
                  {
                    title: feed.name,
                  },

                ]}
              />
            </Col>
            {feed.type !== 2 && <Col span={14}>
              <ul className="DashboardMenu">
                <li className="active">
                  <Link to={`/feeds/${feed.id}`}>Главная</Link>
                </li>
                <li className="">
                  <Link to={`/feeds/${feed.id}/errors`}>Отчет об ошибках</Link>
                </li>
                <li className="">
                  <Link to={`/feeds/${feed.id}/structure`}>Структура фида</Link>
                </li>
                <li className="">
                  <Link to={`/feeds/${feed.id}/content`}>Содержание фида</Link>
                </li>
              </ul>
            </Col>}
          </Row>
        </div>

        <div className="collapse-section">
            <h1>{feed.name}</h1>
            <FeedDashboardOverviewTop dashboardUpdate={dashboardUpdate} id={id} dashboard={dashboard} feed={feed} handleRemoveFeed={handleRemoveFeed} feedupd={dashboard?.updated_at} />
        </div>

      {feed.type == 2 ||
        <>
        <div className="collapse-section">
            <div className="header">
                <h2>Основные показатели</h2>
                <a onClick={handleIsOpenedIndicators}>{isOpenedIndicators ? (
                    <>
                        Свернуть <UpOutlined />
                    </>
                ) : (
                    <>
                        Раскрыть <DownOutlined />
                    </>
                )}
                </a>
            </div>
            <Collapse isOpened={isOpenedIndicators}>
              <Spin spinning={loading} indicator={
               antIcon
              }>
                <Row gutter={[30, 15]}>
                  <Col xs={{ span: 10 }} xxl={{ span: 5 }}>
                    <div className="feeddashboardcard">
                      <div className="feeddashboardcard-title">
                        Отчёт об ошибках
                        <Link to={`/feeds/${feed.id}/errors`}>Подробнее</Link>
                      </div>
                      <div className="feeddashboardcard-content">
                        <FeedDashboardOverviewStat value={dashboard?.errors || 0} desc="Ошибок" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={{ span: 14 }} xxl={{ span: 6 }}>
                    <div className="feeddashboardcard">
                      <div className="feeddashboardcard-title">
                        Структура фида
                        <Link to={`/feeds/${feed.id}/structure`}>Подробнее</Link>
                      </div>
                      <div className="feeddashboardcard-content">
                        <Row gutter={30}>
                          <Col span={12}>
                            <FeedDashboardOverviewStat
                              value={dashboard?.standard || 0}
                              desc="Стандартных тегов"
                            />
                          </Col>
                          <Col span={12}>
                            <FeedDashboardOverviewStat
                              value={dashboard?.non_standard || 0}
                              desc="Специфических тегов"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} xxl={{ span: 13 }}>
                    <div className="feeddashboardcard">
                      <div className="feeddashboardcard-title">
                        Содержание фида{" "}
                        <Link to={`/feeds/${feed.id}/content`}>Подробнее</Link>
                      </div>

                      <div className="feeddashboardcard-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}>
                          <FeedDashboardOverviewStat
                            precision={0}
                            showall={true}
                            value={dashboard?.offers_count || 0}
                            desc="Всего предложений"
                          />
                          <FeedDashboardOverviewStat
                            precision={0}
                            value={dashboard?.categories_count || 0}
                            desc="Категорий"
                          />
                          <FeedDashboardOverviewStat
                            precision={0}
                            value={dashboard?.subcategories_count || 0}
                            desc="Подкатегорий"
                          />
                          <FeedDashboardOverviewStat
                            precision={2}
                            value={dashboard?.min_price || 0}
                            desc="Минимальная цена"
                          />
                          <FeedDashboardOverviewStat
                            precision={2}
                            value={dashboard?.max_price || 0}
                            desc="Максимальная цена"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              <Button className="btn" style={{ marginTop: 10 }} type="primary" onClick={() => handleDashboardUpdate(feed.id)}>Обновить</Button>
              </Spin>
            </Collapse>
        </div>
        </>
      }
    </div>
  );
};

export default FeedDashboardOverview;

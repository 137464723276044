import { Breadcrumb, Checkbox, Col, Progress, Row, Table } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import FeedDashboardOverviewTop from "./FeedDashboardOverviewTop";
import { Link } from "react-router-dom";
import FeedDashboardOverviewStat from "./FeedDashboardOverviewStat";
import { useEffect, useState, useCallback, useMemo } from "react";

const TAG_OPTIONS = ['Стандартный тег', 'Специфический тег'];

const columnsIncludeTags = [
  {
    title: "Тег",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      const isParent = record.children && record.children.length > 0;
      const fontWeight = isParent ? "bold" : "normal";
      return <span style={{ fontWeight }}>{text}</span>;
    },
  },
  {
    title: "Товаров с тегом",
    dataIndex: "total",
    key: "total",
    width: 150,
  },
];

const columnsTagStats = [
  {
    title: "Тег",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  {
    title: "Пример значения",
    dataIndex: "example",
    key: "example",
    width: 280,
    ellipsis: true,
  },
  {
    title: "Уникальных значений",
    dataIndex: "unique",
    key: "unique",
    width: 130,
  },
  {
    title: "% Предложений с заполненным полем ",
    dataIndex: "percent",
    key: "percent",
    width: 180,
    render: (percent) => <Progress percent={Math.round(percent)} />,
  },
  {
    title: "Предложений с заполненным полем",
    dataIndex: "count",
    key: "count",
    width: 230,
  },
];

const FeedDashboardStructure = ({ feed, handleRemoveFeed, feedTagStats, feedNestedTags, dashboard }) => {
  const [checkedTags, setCheckedTags] = useState(TAG_OPTIONS);
  const [dataTagsStats, setDataTagsStats] = useState([]);

  const onChangeTags = (checkedValues) => {
    setCheckedTags(checkedValues);
  };

  const prepareFilterTags = useCallback(() => {
    if (!feedTagStats?.length) return;

    if (checkedTags.length === 0) {
      setDataTagsStats([]);
    } else if (checkedTags.length === TAG_OPTIONS.length) {
      setDataTagsStats(feedTagStats);
    } else {
      const isStandardTagSelected = checkedTags.includes('Стандартный тег');
      setDataTagsStats(feedTagStats.filter(row => row.standard === isStandardTagSelected));
    }
  }, [checkedTags, feedTagStats]);

  useEffect(() => {
    prepareFilterTags();
  }, [checkedTags, feedTagStats, prepareFilterTags]);

  const standardTagsCount = useMemo(() => dashboard?.standard || 0, [dashboard]);
  const nonStandardTagsCount = useMemo(() => dashboard?.non_standard || 0, [dashboard]);

  return (
    <div className="FeedDashboardStructure">
      <Row>
        <Col span={10}>
          <Breadcrumb
            items={[
              { title: <HomeOutlined /> },
              { title: <Link to={`/feeds/${feed.id}`}>{feed.name}</Link> },
              { title: "Структура фида" },
            ]}
          />
        </Col>
        <Col span={14}>
          <ul className="DashboardMenu">
            <li><Link to={`/feeds/${feed.id}`}>Главная</Link></li>
            <li><Link to={`/feeds/${feed.id}/errors`}>Отчет об ошибках</Link></li>
            <li className="active"><Link to={`/feeds/${feed.id}/structure`}>Структура фида</Link></li>
            <li><Link to={`/feeds/${feed.id}/content`}>Содержание фида</Link></li>
          </ul>
        </Col>
      </Row>

      <h1>Структура фида</h1>
      <FeedDashboardOverviewTop feed={feed} handleRemoveFeed={handleRemoveFeed} feedupd={dashboard?.updated_at} />
      <Row>
        <Col span={6}>
          <div className="feeddashboardcard">
            <div className="feeddashboardcard-title">Структура фида</div>
            <div className="feeddashboardcard-content">
              <Row gutter={30}>
                <Col span={12}>
                  <FeedDashboardOverviewStat value={standardTagsCount} desc="Стандартных тегов" />
                </Col>
                <Col span={12}>
                  <FeedDashboardOverviewStat value={nonStandardTagsCount} desc="Специфических тегов" />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col>
          <div className="FeedDashboardStructureshowTags">
            <div className="FeedDashboardStructureshowTags_title">Показать теги</div>
            <Checkbox.Group options={TAG_OPTIONS} defaultValue={TAG_OPTIONS} onChange={onChangeTags} />
          </div>
        </Col>
      </Row>
      <h2 className="FeedDashboardStructureshowTagsTitle" style={{ marginBottom: 30, marginTop: 40 }}>Теги с данными в фиде</h2>
      <Col span={16}>
        <Table columns={columnsTagStats} bordered pagination={false} dataSource={dataTagsStats} />
      </Col>
      <h2 className="FeedDashboardStructureshowTagsTitle" style={{ marginBottom: 30, marginTop: 40 }}>Вложенные теги</h2>
      <Col span={12}>
        <Table columns={columnsIncludeTags} bordered pagination={true} dataSource={feedNestedTags} />
      </Col>
    </div>
  );
};

export default FeedDashboardStructure;

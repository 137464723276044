import { useState } from "react";
import "./alert.css";
const MyAlert = ({ type = "error", message, title, btnClose, vertical=false, extraBtn = false, extraBtnText }) => {

  const [isVisible, setIsVisible] = useState(true);

  const handleBtn = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }
  return (
    <div className={`attention alert ${vertical?'vertical':''}`}>
      <div className={`alert_icon alerttype_${type}`}>
        
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.0001 37.9334C18.707 37.9369 17.436 37.5976 16.3167 36.9501L7.15007 31.6667C6.03634 31.0179 5.11187 30.0888 4.4686 28.9718C3.82533 27.8549 3.48567 26.589 3.4834 25.3001V14.7001C3.48567 13.4111 3.82533 12.1452 4.4686 11.0283C5.11187 9.91132 6.03634 8.98222 7.15007 8.33339L16.3167 3.05006C17.4366 2.4035 18.7069 2.06311 20.0001 2.06311C21.2932 2.06311 22.5635 2.4035 23.6834 3.05006L32.8501 8.33339C33.9638 8.98222 34.8883 9.91132 35.5315 11.0283C36.1748 12.1452 36.5145 13.4111 36.5167 14.7001V25.3001C36.5145 26.589 36.1748 27.8549 35.5315 28.9718C34.8883 30.0888 33.9638 31.0179 32.8501 31.6667L23.6834 36.9501C22.565 37.5998 21.2935 37.9393 20.0001 37.9334ZM20.0001 4.60006C19.1482 4.60045 18.3107 4.81845 17.5667 5.23339L8.3334 10.5001C7.61122 10.9364 7.01487 11.5529 6.60289 12.2892C6.1909 13.0256 5.97742 13.8563 5.9834 14.7001V25.3001C5.97742 26.1438 6.1909 26.9746 6.60289 27.7109C7.01487 28.4472 7.61122 29.0638 8.3334 29.5001L17.5001 34.8001C18.2441 35.2146 19.0817 35.4321 19.9334 35.4321C20.7851 35.4321 21.6227 35.2146 22.3667 34.8001L31.6667 29.5001C32.4013 29.0721 33.011 28.4591 33.435 27.7222C33.859 26.9853 34.0825 26.1502 34.0834 25.3001V14.7001C34.0825 13.8499 33.859 13.0148 33.435 12.2779C33.011 11.541 32.4013 10.9281 31.6667 10.5001L22.5001 5.20006C21.7365 4.77397 20.8744 4.55559 20.0001 4.56672V4.60006Z" fill="currentColor"/>
<path d="M20.0002 22.3334C20.4422 22.3334 20.8661 22.1578 21.1787 21.8452C21.4912 21.5327 21.6668 21.1088 21.6668 20.6667V12.9001C21.6668 12.458 21.4912 12.0341 21.1787 11.7216C20.8661 11.409 20.4422 11.2334 20.0002 11.2334C19.5581 11.2334 19.1342 11.409 18.8217 11.7216C18.5091 12.0341 18.3335 12.458 18.3335 12.9001V20.6667C18.3335 21.1088 18.5091 21.5327 18.8217 21.8452C19.1342 22.1578 19.5581 22.3334 20.0002 22.3334Z" fill="currentColor"/>
<path d="M19.9998 28.7668C21.1504 28.7668 22.0832 27.834 22.0832 26.6834C22.0832 25.5328 21.1504 24.6001 19.9998 24.6001C18.8492 24.6001 17.9165 25.5328 17.9165 26.6834C17.9165 27.834 18.8492 28.7668 19.9998 28.7668Z" fill="currentColor"/>
</svg>

        
        </div>
      {title && <div className="alert_title">{title}</div>}
      <div className="alert_message">{message}</div>
      {btnClose ? (
        <div
          className="alert_btn btn"
          onClick={() => {
            handleBtn();
          }}
        >
          {btnClose}
        </div>
      ) : null}
      {extraBtn ? (
        <div
          className="alert_btn btn"
          onClick={() => {
            extraBtn();
          }}
        >
          {extraBtnText}
        </div>
      ) : null}
    </div>
  );
};
export default MyAlert;

import Login from "../pages/Login";
import Register from "../pages/Register";
import PrivateRoute from "../components/PrivateRoute";
import Main from "../pages/Main";
import Logout from "../pages/Logout";
import NotFound from "../pages/NotFound";
import { Route, Routes } from "react-router-dom";
import Feed from "../pages/Feed";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../index";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import FeedDashboardErrorPage from "../pages/FeedDashboardErrorPage";
import FeedDashboardContentPage from "../pages/FeedDashboardContentPage";
import FeedDashboardStructurePage from "../pages/FeedDashboardStructurePage";
import ProjectIntegrationsPage from "../pages/ProjectIntegrationsPage";

const AppRouter = () => {
  const { store } = useContext(Context);

  useEffect(() => {
    store.checkAuth();
    // eslint-disable-next-line
  }, []);

  const [openKeys, setOpenKeys] = useState([]);
  const [openMenuItem, setOpenMenuItem] = useState([]);
  const handleOpenChange = (d) => {
    setOpenKeys(d);
  };

  if (store.isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "90vh",
        }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />
      </div>
    );
  }

  // if (!store.isAuth && !store.isLoading) {
  //     // Если пользователь не авторизован и загрузка завершена,
  //     // перенаправляем его на страницу входа
  //     return <Navigate to="/login" />;
  // }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/registration" element={<Register />} />

      <Route
        element={
          <PrivateRoute
            isAuth={store.isAuth}
            openKeys={openKeys}
            handleOpenChange={handleOpenChange}
            openMenuItem={openMenuItem}
            setOpenMenuItem={setOpenMenuItem}
          />
        }
      >
        <Route index element={<Main />} />
        <Route path="/" element={<Main />} />
        <Route
          path="/feeds/:id"
          element={
            <Feed
              handleOpenChange={handleOpenChange}
              setOpenMenuItem={setOpenMenuItem}
            />
          }
        />
        <Route
          path="/feeds/:id/errors"
          element={
            <FeedDashboardErrorPage
              handleOpenChange={handleOpenChange}
              setOpenMenuItem={setOpenMenuItem}
            />
          }
        />
        <Route
          path="/feeds/:id/content"
          element={
            <FeedDashboardContentPage
              handleOpenChange={handleOpenChange}
              setOpenMenuItem={setOpenMenuItem}
            />
          }
        />
        <Route
          path="/feeds/:id/structure"
          element={
            <FeedDashboardStructurePage
              handleOpenChange={handleOpenChange}
              setOpenMenuItem={setOpenMenuItem}
            />
          }
        />
        <Route path="/integrations/:id" element={<ProjectIntegrationsPage handleOpenChange={handleOpenChange} />} />
        <Route path="/logout" element={<Logout />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default observer(AppRouter);

import { Button, Modal } from "antd";
import MyAlert from "../Alerts/Alert";
import warning from "antd/es/_util/warning";

const ErrorModal = ({
  isModalErrorOpen,
  setIsModalErrorOpen,
  btnText,
  text,
  btnCallback,
  type = warning
}) => {
  const handleCancel = () => {
    setIsModalErrorOpen(false);
  };

  return (
    <>
      <Modal
        title={null} // Применение стилей к заголовку
        open={isModalErrorOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <div className="errormodal_content ">
          <div className="content">
            <MyAlert message={text} vertical={true} type={type} />
          </div>
          <div className="btns">
            <Button size="large" style={{width: '100%'}} type="primary" onClick={btnCallback?btnCallback:handleCancel}>
              {btnText}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ErrorModal;

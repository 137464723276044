import {  Outlet } from "react-router-dom";
// import { Navigate } from 'react-router-dom';
import MainLayout from "../components/Layout/MainLayout";
// import { useEffect } from "react";
import Login from "../pages/Login";
const PrivateRoute = ({isAuth,checkAuth,openKeys,handleOpenChange,setOpenMenuItem,openMenuItem}) => {

  

  return isAuth ?  <MainLayout openKeys={openKeys} handleOpenChange={handleOpenChange} openMenuItem={openMenuItem}  setOpenMenuItem={setOpenMenuItem}><Outlet /></MainLayout> :  <Login />;
};
  
export default PrivateRoute;
 
import { Button, Flex, Form, Input, Modal, Tabs, message } from "antd";
import {  useState } from "react";
import Zoomos from "../Integarations/Zoomos";
import ErrorModal from "../../ui/Modals/ErrorModal";
import { useNavigate } from "react-router-dom";
import AutoCompleteFormula from "../Renders/AutoCompleteFormula";
import ElifForm from "../../Forms/Feeds/AddNewColumn/elifForm";
import ExtractStringForm from "../../Forms/Feeds/AddNewColumn/extractStringForm";

const ModalAddColumn = ({
  tableid,
  addNewColumn,
  fetchFeedColumns,
  fetchData,
  feedColumns,
  getIntegrations,
  getIntegration,
  projectId,
  tablerows,
  addColumnWithType,
  updateTable,
  setLoading,
  updateData,
  fetchFeed,
  selectColumns,
}) => {
  // SAVE NEW FEED
  const navigate = useNavigate();
  const [newColumnState, setNewColumnState] = useState({
    title: "",
    default: "",
    tab: "1",
  });
  const [newColumnCurrentDate, setNewColumnCurrentDate]= useState({
    title: "current_date",
    default: "currentDate",
    tab: "1",
  });
  const [addNewColumnModalVisible, setAddNewColumnModalVisible] =
    useState(false);
  const [showIntegrations, setShowIntegrations] = useState(true);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [addZoomosModalVisible, setAddZoomosModalVisible] = useState(false);
  const [ZoomosData, setZoomosData] = useState([]);
  const [errTitle, setErrTitle] = useState("");
  const [errFormula, setErrFormula] = useState("");

  // TODO
  // переименовать newColumnState стейт в newColumnState
  // добавить параметры столбцов условия и вычисления
  // добавить формы столбцов условия и вычисления
  // сделать им функции

  const storeAddColumnWithType = async (attr_name, def) => {
    console.log("attr_name", attr_name);
    await addColumnWithType(
      tableid,
      newColumnState.title,
      attr_name,
      6,
      def
    ).then((response) => {
      console.log("response", response);
      if (response.status === 200 || response.status === 201) {
        console.log(200);
        clearStates();
        setLoading(true);
        setTimeout(() => {
          updateData();
        }, 11000);
        //updateData();
        setNewColumnState({ title: "", default: "", tab: "1" });
      } else if (response.status === 422) {
        console.log(422);
        setErrTitle(response.data.detail);
        console.log(errTitle)
      }
    });
  };

  const storeAddColumnCurrentDate = async (attr_name, def) => {
    console.log("attr_name", attr_name);
    await addColumnWithType(
        tableid,
        newColumnCurrentDate.title,
        attr_name,
        6,
        def
    ).then((response) => {
      console.log("response", response);
      if (response.status === 200 || response.status === 201) {
        console.log(200);
        clearStates();
        setLoading(true);
        setTimeout(() => {
          updateData();
        }, 11000);
        //updateData();
        setNewColumnState({ title: "", default: "", tab: "1" });
      } else if (response.status === 422) {
        console.log(422);
        setErrTitle(response.data.detail);
        console.log(errTitle)
      }
    });
  };

  const showModalAddNewColumn = () => {
    setAddNewColumnModalVisible(true);
  };

  const clearStates = () => {
    setErrTitle("");
    setNewColumnState({ title: "", default: "", tab: "1" });
    setErrFormula("");
    setConditions([]);
    setElseValue("");
    setFormExtractState({});
    setAddNewColumnModalVisible(false);
  };

  const handleaAddNewColumnModalModalOk = () => {
    console.log("handleaAddNewColumnModalModalOk");
    if (newColumnState.title === "") {
      setErrTitle("Укажите название столбца");
      return;
    }
    let errors = 0;
    if (newColumnState.tab === "1") {
      if (newColumnState.default === "") {
        setErrFormula("Укажите формулу");
        return;
      }

      storeAddColumnWithType("calculated", newColumnState.default);
    } else if (newColumnState.tab === "2") {
      const fieldslist = ["column", "operator", "value", "output"];
      console.log(conditions);

      const newConditions = [...conditions];
      newConditions.forEach((condition) => {
        fieldslist.forEach((field) => {
          if (
            field === "value" &&
            (condition["operator"] === "is blank" ||
              condition["operator"] === "is not blank")
          ) {
          } else {
            if (condition[field] === "") {
              errors++;
              condition.errors[field] = "Поле не заполнено";
            }
          }
        });
      });
      console.log(errors, newConditions);
      setConditions(newConditions);
      if (errors === 0) {
        console.log(conditions);
        const defalutConditions = conditions.map((condition) => {
          return {
            column: condition.column,
            operator: condition.operator,
            value: condition.value,
            result: condition.output,
          };
        });
        console.log(defalutConditions, elseValue);
        storeAddColumnWithType("condition", {
          conditions: defalutConditions,
          result: elseValue,
        });
      }
    } else if (newColumnState.tab === "3") {
      console.log(formExtractState);
      const newState = { ...formExtractState };
      if (!formExtractState.column) {
        errors++;
        newState.errors.column = "Поле не заполнено";
      }
      if (!formExtractState.type) {
        errors++;
        newState.errors.type = "Поле не заполнено";
      } else if (formExtractState.type === "between") {
        if (!formExtractState.before) {
          errors++;
          newState.errors.before = "Поле не заполнено";
        }
        if (!formExtractState.after) {
          errors++;
          newState.errors.after = "Поле не заполнено";
        }
      } else if (formExtractState.type === "before") {
        if (!formExtractState.before) {
          errors++;
          newState.errors.before = "Поле не заполнено";
        }
      } else if (formExtractState.type === "after") {
        if (!formExtractState.after) {
          errors++;
          newState.errors.after = "Поле не заполнено";
        }
      }
      setFormExtractState(newState);

      if (errors === 0) {
        const defaultvalex = {
          mode: formExtractState.type,
          column: formExtractState.column,
          relative: formExtractState.relative,
        };
        if (formExtractState.type === "between") {
          defaultvalex.separator1 = formExtractState.before;
          defaultvalex.separator2 = formExtractState.after;
        } else if (formExtractState.type === "before") {
          defaultvalex.separator1 = formExtractState.before;
          defaultvalex.separator2 = null;
        } else if (formExtractState.type === "after") {
          defaultvalex.separator2 = null;
          defaultvalex.separator1 = formExtractState.after;
        }
        // console.log(defaultvalex);
        storeAddColumnWithType("extraction", defaultvalex);
        // clearStates();
      }
    } else {
      console.log(newColumnState.tab);
    }
  };

  const handleAddColumnCurrentDate = () => {
    console.log("handleAddColumnCurrentDate");
    storeAddColumnCurrentDate("current_date", newColumnCurrentDate.default);
  };

  const handleAddNewColumnModalModalCancel = () => {
    clearStates();
  };

  const [messageApi, contextHolder] = message.useMessage();
  const loading = () => {
    messageApi.open({
      type: "loading",
      content: "Обрабатываем данные..",
      duration: 0,
    });
    // Dismiss manually and asynchronously
    // setTimeout(messageApi.destroy, 2500);
  };

  const [integrationSelected, setIntegrationSelected] = useState([null, null]);

  const selectIntegration = (type, id) => {
    setIntegrationSelected([type, id]);
    setShowIntegrations(false);
  };

  const prepareData = (data) => {
    console.log(data);
    data = data.filter((row) => row.status === 1);
    return data.map((row) => {
      return {
        key: row.id,
        name: row.name,
        update: [row.updated_at, row.created_at],
        status: row.status,
        type: row.type,
        infeeds:
          row.status === 1 ? (
            <Flex justify="space-between" align="center">
              <Button
                type="primary"
                className="btn"
                onClick={() => selectIntegration(row.type, row.id)}
              >
                Добавить интеграцию
              </Button>
            </Flex>
          ) : (
            ""
          ),
      };
    });
  };

  // useEffect(() => {
  //   console.log(ZoomosData);
  // }, [ZoomosData]);

  const showModalAddZoomos = async () => {
    const response = await getIntegrations(projectId);
    const prepdata = prepareData(response.data);
    setZoomosData(prepdata);
    if (prepdata.length > 0) {
      setShowIntegrations(true);
      setAddZoomosModalVisible(true);
    } else {
      setIsModalErrorOpen(true);
    }
  };
  const handleaAddZoomosModalModalOk = () => {
    setShowIntegrations(true);
    setAddZoomosModalVisible(false);
  };
  const handleAddZoomosModalModalCancel = () => {
    setIntegrationSelected([null, null]);
    console.log("cancel");
    setShowIntegrations(true);
    setAddZoomosModalVisible(false);
  };
  const closeModal = () => {
    setIntegrationSelected([null, null]);
    setAddZoomosModalVisible(false);
  };
  const clearErrTitle = () => {
    setErrTitle("");
  };
  const submitModal = async (values, integration_id, attr_name) => {
    console.log("submitModal", attr_name);
    const title = values.title;
    delete values.title;
    values.integration_id = integration_id;
    let attrname = attr_name === 1 ? "zoomos" : "google";

    await addColumnWithType(tableid, title, attrname, 7, values).then((response) => {
      console.log("addColumnWithTyp2e",response.status );

      if (response.status === 200 || response.status === 201) {
        console.log(200);
        setLoading(true);
        clearStates();
        loading();
        setTimeout(() => {
          updateData();
        }, 11000);
        //updateData();
        closeModal();
      } else if (response.status === 422) {
        console.log(422);
        setErrTitle(response.data.detail);
      }
    });
    messageApi.destroy();

    // //обновление фида за кнопкой
  };
  const setAc = (ac) => {
    console.log("AC: " + ac);
    setNewColumnState((prev) => ({ ...prev, default: ac }));
  };

  const onTabChange = (key) => {
    // console.log(key);
    setNewColumnState((prev) => ({ ...prev, tab: key }));
  };

  const [conditions, setConditions] = useState([]);
  const [elseValue, setElseValue] = useState("");
  const [formExtractState, setFormExtractState] = useState({
    column: null,
    before: null,
    after: null,
    type: null,
    errors: {},
  });
  const items = [
    {
      key: "1",
      label: "Вычисление",
      children: (
        <>
          <AutoCompleteFormula
            setErrFormula={setErrFormula}
            errFormula={errFormula}
            values={selectColumns}
            inputValue={newColumnState.default}
            setInputValue={setAc}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "Условие",
      children: (
        <ElifForm
          columns={selectColumns}
          conditions={conditions}
          setConditions={setConditions}
          elseValue={elseValue}
          setElseValue={setElseValue}
        />
      ),
    },
    {
      key: "3",
      label: "Извлечение текста",
      children: (
        <ExtractStringForm
          columns={selectColumns}
          formExtractState={formExtractState}
          setFormExtractState={setFormExtractState}
        />
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div style={{ width: 280 }}>
        {integrationSelected[0]}
        <ul className="sidebar_parentmenu-list">
          <li onClick={showModalAddNewColumn}>
            <span>Добавить вычисляемый столбец</span>
          </li>
          <li onClick={handleAddColumnCurrentDate}>
            <span>Добавить столбец текущей даты</span>
          </li>
          <li onClick={showModalAddZoomos}>
            <span>Подключить Интеграцию</span>
          </li>
        </ul>
      </div>

      <Modal
        open={addNewColumnModalVisible}
        onOk={handleaAddNewColumnModalModalOk}
        onCancel={handleAddNewColumnModalModalCancel}
        title="Добавление нового столбца"
        width={1000}
      >
        <Form.Item>
          <Input
            value={newColumnState.title}
            placeholder="Укажите название"
            onChange={(e) => {
              setErrTitle("");
              setNewColumnState((prev) => ({ ...prev, title: e.target.value }));
            }}
          />
          {errTitle && <div style={{ color: "red" }}>{errTitle}</div>}
        </Form.Item>
        <Tabs
          defaultActiveKey="1"
          activeKey={newColumnState.tab}
          type="card"
          items={items}
          onChange={onTabChange}
        />
        {errFormula && <div style={{ color: "red" }}>{errFormula}</div>}
        {/* {JSON.stringify(newColumnState)} */}
      </Modal>
      <Modal
        open={addZoomosModalVisible}
        onOk={handleaAddZoomosModalModalOk}
        onCancel={handleAddZoomosModalModalCancel}
        title={
          integrationSelected[0] !== null
            ? `Интеграция ${integrationSelected[0] === 1 ? "Zoomos" : "Google"}`
            : "Выберите интеграцию"
        }
        width={1000}
        footer=""
      >
        <>
          <Zoomos
            getIntegrations={getIntegrations}
            getIntegration={getIntegration}
            projectId={projectId}
            id={tableid}
            errTitle={errTitle}
            clearErrTitle={clearErrTitle}
            ZoomosData={ZoomosData}
            setZoomosData={setZoomosData}
            tablerows={tablerows}
            closeModal={closeModal}
            submitModal={submitModal}
            feedColumns={feedColumns}
            selectColumns={selectColumns}
            showIntegrations={showIntegrations}
            setShowIntegrations={setShowIntegrations}
            integrationSelected={integrationSelected}
          />
        </>
      </Modal>
      <ErrorModal
        isModalErrorOpen={isModalErrorOpen}
        setIsModalErrorOpen={setIsModalErrorOpen}
        btnText="Перейти к интеграциям"
        btnCallback={() => navigate(`/integrations/${projectId}`)}
        text="Вы не настроили ни одной интеграции"
      />
    </>
  );
};
export default ModalAddColumn;

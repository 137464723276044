import { Button, Modal } from "antd";

const DeleteModal = ({
  isModalDeleteOpen,
  setIsModalDeleteOpen,
  onConfirmDelete,
  title,
  text,
}) => {
  const handleCancel = () => {
    setIsModalDeleteOpen(false);
  };
  const modalTitleStyle = {
    textAlign: 'center', // Выравнивание текста по центру
    width: '100%'       // Убедитесь, что заголовок занимает всю ширину
  };
  return (
    <>
      <Modal
        title={<div style={modalTitleStyle}>{title}</div>} // Применение стилей к заголовку
open={isModalDeleteOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <div className="deletemodal_content">
        <div className="content">{text}</div>
        <div className="btns">
         
            <Button className="btn" size="large"  onClick={handleCancel}>Отмена</Button>
            <Button size="large" type="primary" onClick={onConfirmDelete}>Да</Button>
         
        </div>
        </div>
      </Modal>
    </>
  );
};
export default DeleteModal;

import { useState } from 'react';
const useFeedModals = () => {
const [editModalVisible, setEditModalVisible] = useState(false);
const [deleteModalVisible, setDeleteModalVisible] = useState(false);
const [newTitle, setNewTitle] = useState(null);
const [currColumn, setCurrColumn] = useState(null);

const showModalEdit = (title, column) => {
  setCurrColumn(column);
  setNewTitle(title);
  setEditModalVisible(true);
};
const showModalDelete = (title, column) => {
  setCurrColumn(column);
  setNewTitle(title);
  setDeleteModalVisible(true);
}

return {
    editModalVisible,
    setEditModalVisible,
    deleteModalVisible,
    setDeleteModalVisible,
    showModalEdit,
    showModalDelete,
    setNewTitle,
    currColumn,
    newTitle
  };

}
export default useFeedModals;

import { useContext, useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import FeedDashboardContent from "../components/Feed/FeedDashboardContent";
import { Context } from "..";



const FeedDashboardContentPage = ({handleOpenChange,setOpenMenuItem}) => {

  const { store } = useContext(Context);
  const [feed, setFeed] = useState();
  const [feedContent, setFeedContent] = useState();
  const [dashboardStats, setDashboardStats] = useState();
  const prepareFeedContent= (data)=>{
    if(data && data.content){
      return data.content.map((el)=>{
        let tmp = {
          ...el,
          key: el.id,
          subcategories_count: el.children?.length || 0,
          children: prepareChildren(el.children)
        }; 
        return tmp;
      })
    }
    else{
      return [];
    }
  }
  const prepareChildren = (children)=>{
    if(children && children.length){
      return children.map((el)=>{
        let tmp = {
          ...el,
          key: el.id,
          children: null,
          //TODO recursive children
          // subcategories_count: el.children.length || 0,
          // children: prepareChildren(el.children)
        }; 
        return tmp;
      })
    } 
    else{
      return null;
    }
    // return [];
  }

  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchFeed = async () => {
      try {
        await store.getFeed(id).then((feed) => {
          if (!feed) {
            navigate("/404");
          }
          handleOpenChange([`parent_${feed.data.project}`,`table_${feed.data.id}`,feed.data.parent?`table_${feed.data.parent}`:null]);
          setOpenMenuItem([`table_${feed.data.id}`])
          setFeed(feed.data);
        });
        await store.getFeedContent(id).then((feedContent)=>{
          setFeedContent(prepareFeedContent(feedContent.data));
          setDashboardStats({
            ...feedContent.data,
            content: null
          })
        })
      } catch (error) {
        console.error("Error fetching feed:", error);
      }
    };
    fetchFeed();
    // eslint-disable-next-line
  }, [id]);

  const handleRemoveFeed = () => {
    store.removeFeed(id);
    navigate("/");
  };


  return (
   
    <> {feed && feed.processing_status === 3 && (
      <FeedDashboardContent feed={feed} feedContent={feedContent} dashboard={dashboardStats} handleRemoveFeed={handleRemoveFeed} />
      )}</>
     
      
  
  );
};

export default FeedDashboardContentPage;

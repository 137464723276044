import { useContext, useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import FeedDashboardStructure from "../components/Feed/FeedDashboardStructure";
import { Context } from "..";



const FeedDashboardStructurePage = ({handleOpenChange,setOpenMenuItem}) => {

  const { store } = useContext(Context);
  const [feed, setFeed] = useState();
  const [feedTagStats, setFeedTagStats] = useState();
  const [dashboardStats, setDashboardStats] = useState();
  const [feedNestedTags, setFeedNestedTags] = useState();

  const prepareFeedStructureTagStats = (data)=>{
    if(data && data.length){
      return data.map((el,idx)=>{
        let tmp = {
          ...el,
          key: idx,
        }; 
        return tmp;
      })
    }
    else{
      return [];
    }
  }
  const prepareFeedStructureNestedTags = (data)=>{
    if(data && data.length){
      return data.map((el,idx)=>{
        let tmp = {
          ...el,
          key: idx,
          children: prepareChildren(el.children),
        }; 
        return tmp;
      })
    }
    else{
      return [];
    }
  }
  const prepareChildren = (children)=>{
    if(children && children.length){
      return children.map((el)=>{
        let tmp = {
          ...el,
          key: el.id,
          children: null,
          //TODO recursive children
          // subcategories_count: el.children.length || 0,
          // children: prepareChildren(el.children)
        }; 
        return tmp;
      })
    } 
    else{
      return [];
    }
    // return [];
  }
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchFeed = async () => {
      try {
        await store.getFeed(id).then((feed) => {
          if (!feed) {
            navigate("/404");
          }
          handleOpenChange([`parent_${feed.data.project}`,`table_${feed.data.id}`,feed.data.parent?`table_${feed.data.parent}`:null]);

          setOpenMenuItem([`table_${feed.data.id}`])
          setFeed(feed.data);
        });
        await store.getFeedStructure(id).then((feedStructure)=>{
          setFeedTagStats(prepareFeedStructureTagStats(feedStructure.data.tag_stats));
          setFeedNestedTags(prepareFeedStructureNestedTags(feedStructure.data.nested_tags));
          setDashboardStats({
            ...feedStructure.data,
           tag_stats: null,
           nested_tags: null
          })
         
        })

      } catch (error) {
        console.error("Error fetching feed:", error);
      }
    };
    fetchFeed();
    // eslint-disable-next-line
  }, [id]);

  const handleRemoveFeed = () => {
    store.removeFeed(id);
    navigate("/");
  };


  return (
   
    <> {feed && feed.processing_status === 3 && (
      <FeedDashboardStructure feed={feed} feedTagStats={feedTagStats} feedNestedTags={feedNestedTags} dashboard={dashboardStats} handleRemoveFeed={handleRemoveFeed} />
      )}</>
     
      
  
  );
};

export default FeedDashboardStructurePage;

import React, { useState } from "react";
import { Form, Select, Input, Button, Space, Flex } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const operators = [
  { value: "is blank", label: "Пусто" },
  { value: "is not blank", label: "Не пусто" },
  // { value: "is equal", label: "Равно" },
  // { value: "is not equal", label: "Не равно" },
  { value: "contains", label: "Содержит" },
  { value: "not contains", label: "Не содержит" },
  { value: "==", label: "Равно" },
  { value: "!=", label: "Не равно" },
  { value: "<", label: "Меньше" },
  { value: ">", label: "Больше" },
  { value: ">=", label: "Больше или равно" },
  { value: "<=", label: "Меньше или равно" },
];

const FormComponent = ({
  columns,
  conditions,
  setConditions,
  elseValue,
  setElseValue,
}) => {
  const [form] = Form.useForm();

  const addCondition = () => {
    setConditions([
      ...conditions,
      { column: "", operator: "", value: "", output: "", errors: {} },
    ]);
  };

  const removeCondition = (index) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setConditions(newConditions);
  };

  const handleConditionChange = (index, field, value) => {
    const newConditions = [...conditions];
    newConditions[index][field] = value;
    newConditions[index].errors[field] = "";
    setConditions(newConditions);
  };

  const handleElseChange = (value) => {
    setElseValue(value);
  };
  const filterOption = (input, option) => {
    // console.log(input, option.children, option.children.toLowerCase().includes(input.toLowerCase()))
    return option.children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form form={form} layout="vertical">
      {conditions.map((condition, index) => (
        <Flex justify="space-between" key={index} align="center">
          <div>Если</div>
          <Form.Item label="Имя столбца">
            <Select
              placeholder="Имя столбца"
              value={condition.column}
              onChange={(value) =>
                handleConditionChange(index, "column", value)
              }
              style={{ width: "200px" }}
              showSearch
              filterOption={filterOption}
              allowClear
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                  >
                    <Input
                      placeholder="Своё значение"
                      style={{ flex: "auto" }}
                      onChange={(e) =>
                        handleConditionChange(index, "column", e.target.value)
                      }
                    />
                  </div>
                </div>
              )}
            >
              {columns.map((col) => (
                <Option key={col.value} value={col.value}>
                  {col.label}
                </Option>
              ))}
            </Select>
            {condition?.errors?.column && (
              <div className="err" style={{ color: "red" }}>
                {condition.errors["column"]}
              </div>
            )}
          </Form.Item>
          <Form.Item label="Оператор">
            <Select
              placeholder="Оператор"
              value={condition.operator}
              onChange={(value) =>
                handleConditionChange(index, "operator", value)
              }
              style={{ width: "200px" }}
            >
              {operators.map((op) => (
                <Option key={op.value} value={op.value}>
                  {op.label}
                </Option>
              ))}
            </Select>
            {condition?.errors?.operator && (
              <div className="err" style={{ color: "red" }}>
                {condition.errors["operator"]}
              </div>
            )}
          </Form.Item>
          {condition.operator !== 'is blank' && condition.operator !== 'is not blank' ? (
          <Form.Item label="Значение">
            <Select
              placeholder="Значение"
              value={condition.value}
              onChange={(value) => handleConditionChange(index, "value", value)}
              style={{ width: "200px" }}
              showSearch
              allowClear
              filterOption={filterOption}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                  >
                    <Input
                      placeholder="Своё значение"
                      style={{ flex: "auto" }}
                      onChange={(e) =>
                        handleConditionChange(index, "value", e.target.value)
                      }
                    />
                  </div>
                </div>
              )}
            >
              {columns.map((col) => (
                <Option key={col.value} value={col.value}>
                  {col.label}
                </Option>
              ))}
            </Select>
            {condition?.errors?.value && (
              <div className="err" style={{ color: "red" }}>
                {condition.errors["value"]}
              </div>
            )}
          </Form.Item>
          ):''}
          <Form.Item label="Вывод">
            <Select
              placeholder="Вывод"
              value={condition.output}
              onChange={(value) =>
                handleConditionChange(index, "output", value)
              }
              style={{ width: "200px" }}
              showSearch
              allowClear
              filterOption={filterOption}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                  >
                    <Input
                      placeholder="Своё значение"
                      style={{ flex: "auto" }}
                      onChange={(e) =>
                        handleConditionChange(index, "output", e.target.value)
                      }
                    />
                  </div>
                </div>
              )}
            >
              {columns.map((col) => (
                <Option key={col.value} value={col.value}>
                  {col.label}
                </Option>
              ))}
            </Select>
            {condition?.errors?.output && (
              <div className="err" style={{ color: "red" }}>
                {condition.errors["output"]}
              </div>
            )}
          </Form.Item>
          <MinusCircleOutlined onClick={() => removeCondition(index)} />
        </Flex>
      ))}
      <Form.Item>
        <Button
          type="dashed"
          onClick={addCondition}
          block
          icon={<PlusOutlined />}
        >
          Добавить условие
        </Button>
      </Form.Item>
      <Form.Item placeholder="Иначе" label="Иначе">
        <Select
          placeholder="Иначе"
          value={elseValue}
          onChange={handleElseChange}
          style={{ width: "100%" }}
          showSearch
          filterOption={filterOption}
          allowClear
          dropdownRender={(menu) => (
            <div>
              {menu}
              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                <Input
                  style={{ flex: "auto" }}
                  onChange={(e) => handleElseChange(e.target.value)}
                />
              </div>
            </div>
          )}
        >
          {columns.map((col) => (
            <Option key={col.value} value={col.value}>
              {col.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

const ElifForm = ({
  columns,
  conditions,
  setConditions,
  elseValue,
  setElseValue,
}) => {
  return (
    <div style={{ padding: "20px" }}>
      <FormComponent
        columns={columns}
        conditions={conditions}
        setConditions={setConditions}
        elseValue={elseValue}
        setElseValue={setElseValue}
      />
    </div>
  );
};

export default ElifForm;

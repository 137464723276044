import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FeedTable2 from "../components/Feed/FeedTable2";
import { Context } from "..";
import FeedDashboardOverview from "../components/Feed/FeedDashboardOverview";
import MyAlert from "../components/ui/Alerts/Alert";
import DeleteModal from "../components/ui/Modals/DeleteModal";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {Line} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import chartTrendline from 'chartjs-plugin-trendline';
import {Collapse} from "react-collapse/lib/Collapse";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, chartTrendline);

dayjs.extend(customParseFormat);

const Feed = ({ handleOpenChange, setOpenMenuItem }) => {
  const { store } = useContext(Context);
  const [feed, setFeed] = useState();
  const [projectId, setProjectId] = useState();
  const [dashboard, setDashboard] = useState();
  const [feedChartOffer, setFeedChartOffer] = useState([]);
  const [feedChartDate, setFeedChartDate] = useState([String]);
  const [feedChartDateEdited, setFeedChartDateEdited] = useState([]);
  const [isOpenedChart, setIsOpenedChart] = useState(true);
  const [minValueOffer, setMinValueOffer] = useState(0);
  const [maxValueOffer, setMaxValueOffer] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();

  const { RangePicker } = DatePicker;
  const dateFormat = 'DD.MM.YYYY';

  const nowDate = new Date();
  const [minDate, setMinDate] = useState(dayjs(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7)).format(dateFormat));
  const [maxDate, setMaxDate] = useState(dayjs(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1)).format(dateFormat));
  const [dateToRP, setDateToRP] = useState(dayjs(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7)).format(dateFormat));
  const [dateFromRP, setDateFromRP] = useState(dayjs(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1)).format(dateFormat));

  // const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

  const handleChangeDate = async (range) => {
    if (range != null) {
      const dateTo = range[0].format(dateFormat);
      const dateFrom = range[1].format(dateFormat);
      setDateToRP(dayjs(dateTo, dateFormat));
      setDateFromRP(dayjs(dateFrom, dateFormat));

      try {
        const filterFeedChartResponse = await store.addFilterFeedChart(feed.id, dateTo, dateFrom);
        setFeedChartOffer(filterFeedChartResponse.data.offers_count);

        const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
        const dates = []
        for (let i = 0; i < filterFeedChartResponse.data.dates.length; i++) {
          const date = new Date(filterFeedChartResponse.data.dates[i].replace(pattern, '$3-$2-$1'));
          const year = date.getFullYear(); // % 100;
          const month = date.toLocaleString("ru-RU", {month: "short"});
          const day = date.toLocaleString("ru-RU", {day: "2-digit"});
          dates.push(`${day} ${month} ${year}`);
        }
        setFeedChartDateEdited(dates);
      } catch (e) {
        console.log("Error fetching chart overview");
      }
    }
  }

  const fetchChart = async () => {
    try {
      const feedChartOverviewResponse = await store.getFeedChartOverview(id);
      if (feedChartOverviewResponse.data.offers_count.length < 2) {
        setIsOpenedChart(false)
      }
      setFeedChartOffer(feedChartOverviewResponse.data.offers_count);
      setMaxValueOffer(Math.max(...feedChartOverviewResponse.data.offers_count));
      setMinValueOffer(Math.min(...feedChartOverviewResponse.data.offers_count));
      setFeedChartDate(feedChartOverviewResponse.data.dates);

      const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      const dates = []
      for (let i = 0; i < feedChartOverviewResponse.data.dates.length; i++) {
        const date = new Date(feedChartOverviewResponse.data.dates[i].replace(pattern,'$3-$2-$1'));
        const year = date.getFullYear(); // % 100;
        const month = date.toLocaleString("ru-RU", { month: "short" });
        const day = date.toLocaleString("ru-RU", { day: "2-digit" });
        dates.push(`${day} ${month} ${year}`);
      }
      setFeedChartDateEdited(dates);
    } catch {
      console.log("Error fetching chart overview");
    }
  };

  const fetchFeed = async () => {
    try {
      const feedResponse = await store.getFeed(id);
      if (!feedResponse) {
        navigate("/404");
      }
      console.log("Feed response:", feedResponse);
      setProjectId(feedResponse.data.project);
      handleOpenChange([
        `parent_${feedResponse.data.project}`,
        `table_${feedResponse.data.id}`,
        feedResponse.data.parent ? `table_${feedResponse.data.parent}` : null,
      ]);
      setOpenMenuItem([`table_${feedResponse.data.id}`]);
      setFeed(feedResponse.data);
    } catch (error) {
      console.error("Error fetching feed:", error);
      navigate("/404");
    }

    try {
      const overviewResponse = await store.getFeedOverview(id);
      setDashboard(overviewResponse.data);
    } catch {
      console.log("Error fetching dashboard overview");
    }

    fetchChart();
  };

  useEffect(() => {
    fetchFeed();
    const interval = setInterval(() => {
      if (feed?.processing_status !== 3 && feed?.processing_status !== -1) {
        // console.log("Feed is not processing");
        fetchFeed();
      }
    }, 60000); // 60000 ms = 1 minute

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [id, feed?.processing_status]);

  useEffect(() => {
    if (feedChartDate.length > 0) {
      const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      const firstItem = feedChartDate[0].toString();
      const lastItem = feedChartDate[feedChartDate.length - 1].toString();
      const firstDate = new Date(firstItem.replace(pattern, '$3-$2-$1'));
      const lastDate = new Date(lastItem.replace(pattern, '$3-$2-$1'));
      const minDate = dayjs(new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate())).format(dateFormat)
      const maxDate = dayjs(new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate())).format(dateFormat)
      setMinDate(minDate);
      setMaxDate(maxDate);
      setDateToRP(minDate);
      setDateFromRP(maxDate);
    } else {
      const minDate = dayjs(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate())).format(dateFormat)
      const maxDate = dayjs(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate())).format(dateFormat)
      setMinDate(minDate);
      setMaxDate(maxDate);
      setDateToRP(minDate);
      setDateFromRP(maxDate);
    }
  }, [feedChartDate]);

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const handleRemoveFeed = () => {
    setIsModalDeleteOpen(true);
  };

  const onConfirmRemoveFeed = async () => {
    await store.removeFeed(id);
    navigate("/");
  };

  const [loading, setLoading] = useState(false);
  const [timerId, setTimerId] = useState(null); // Новый state для хранения идентификатора таймера

  const dashboardUpdate = async (id) => {
    try {
      const data = await store.getFeedOverview(id);
      setDashboard(data.data);
      if (data.data.updating === false) {
        setLoading(false);
        if (timerId) {
          clearTimeout(timerId); // Очищаем таймер, если он существует
          setTimerId(null); // Сбрасываем идентификатор таймера
        }
      }
      return data.data;
    } catch (error) {
      console.error("Failed to update dashboard:", error);
      setLoading(false);
    }

    fetchChart();
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const startDashboardUpdate = async () => {
    console.log("startDashboardUpdate");
    setLoading(true);
    await store.updateFeedOverview(id);
    updateDashboardUntilDone(id);
  };

  const updateDashboardUntilDone = async (id) => {
    console.log("updateDashboardUntilDone", id);
    try {
      console.log("await delay");
      await delay(1000);
      console.log("end delay");
      const data = await dashboardUpdate(id);
      if (data && data.updating === true) {
        console.log("updateDashboardUntilDone NEW");
        updateDashboardUntilDone(id);
      }
    } catch (error) {
      console.error("Failed to initiate update:", error);
      setLoading(false);
    }
  };

  const lineChartData = {
    labels: feedChartDateEdited,
    datasets: [
      {
        data: feedChartOffer,
        label: "Количество офферов",
        borderColor: "#4A5EFF",
        fill: false,
        lineTension: 0, //0.5
        trendlineLinear: {
          //style: "rgb(128, 128, 128, 0.3)",
          colorMin: "rgb(128, 128, 128, 0.4)",
          colorMax: "rgb(128, 128, 128, 0.4)",
          lineStyle: "dotted",
          width: 2
        }
      },
      // {
      //   data: [1216410, 1371390, 1477380],
      //   label: "Deaths",
      //   borderColor: "#ff3333",
      //   backgroundColor: "rgba(255, 0, 0, 0.5)",
      //   fill: true,
      //   lineTension: 0.5
      // }
    ]
  };

  const handleIsOpenedChart = () => {
    setIsOpenedChart(!isOpenedChart);
  };

  return (
    <div>
      {feed?.processing_status === 3 && (
        <>
          <FeedDashboardOverview
            handleRemoveFeed={handleRemoveFeed}
            feed={feed}
            dashboard={dashboard}
            fetchFeed={fetchFeed}
            dashboardUpdate={dashboardUpdate}
            updateDashboardUntilDone={startDashboardUpdate}
            loading={loading}
            dashboardStartUpdate={store.updateFeedOverview}
            id={id}
          />

          <div className="collapse-section">
            <div className="header">
              <h2>График "Количество офферов по дням"</h2>
              <a onClick={handleIsOpenedChart}>{isOpenedChart ? (
                  <>
                    Свернуть <UpOutlined />
                  </>
              ) : (
                  <>
                    Раскрыть <DownOutlined />
                  </>
              )}
              </a>
            </div>
            <Collapse isOpened={isOpenedChart}>
              <div className='row'>
                <div className='col-5 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
				<span className="input-group-text bg-white border-end-0" id="basic-addon2">
				  <i className="bi bi-calendar4-event fs-6 fw-bold" />
				</span>
                    <Space direction="horizontal" size={12}>
                    <RangePicker
                        defaultValue={[dayjs(dateToRP, dateFormat), dayjs(dateFromRP, dateFormat)]}
                        value={[dayjs(dateToRP, dateFormat), dayjs(dateFromRP, dateFormat)]}
                        //placeholder={["", ""]}
                        minDate={dayjs(minDate, dateFormat)}
                        maxDate={dayjs(maxDate, dateFormat)}
                        format={dateFormat}
                        onChange={handleChangeDate}
                    />
                    </Space>
                  </div>


                </div>
              </div>
              <Line
                  type="line"
                  width={160}
                  height={60}
                  options={{
                    plugins: {
                      title: {
                        display: false,
                        text: "Количество офферов",
                        align: 'center',
                        color: 'blue',
                        fullSize: false,
                        position: 'top',
                        font: {
                          size: 20,
                          weight: 'bold',
                          family: 'Arial',
                          style: 'italic'
                        }
                      },
                      legend: {
                        display: false, //Is the legend shown?
                        position: "top" //Position of the legend.
                      }
                    },
                    scales: {
                      y: {
                        // beginAtZero: true,
                        title: {
                          display: false,
                          text: 'Количество'
                        },
                        suggestedMin: minValueOffer,
                        suggestedMax: maxValueOffer
                        // ticks: {
                        //   callback: function(value, index, ticks) {
                        //     value = value.toString();
                        //     if (value.length > 3) {
                        //       value = (value / 100) / 10;
                        //       return value + " тыс.";
                        //     }
                        //     return value;
                        //   }
                        //   //callback: v => v.toString().length > 3 ? v.toString().substring(0, v.toString().length - 1) + ' тыс.' : v
                        // }
                      },
                      x: {
                        title: {
                          display: false,
                          text: 'Период'
                        }
                      }
                    }
                  }}
                  data={lineChartData}
              />
            </Collapse>
          </div>

          <div className="collapse-section">
            <FeedTable2 feed={feed} projectId={projectId} tableid={id} setDashboard={setDashboard} />
          </div>
        </>
      )}
      {feed?.processing_status === -1 && (
        <MyAlert
          message={`Во время обработки файла произошла серверная ошибка. ${feed.details}. Пожалуйста, попробуйте еще раз`}
          title="Серверная ошибка."
          type="error"
          extraBtnText="Удалить"
          extraBtn={handleRemoveFeed}
        />
      )}
      {feed?.processing_status === 0 && (
        <MyAlert
          message="Пожалуйста, подождите"
          title="Фид находится в очереди на обработку."
          type="warning"
        />
      )}
      {feed?.processing_status === 1 && (
        <MyAlert
          message="Пожалуйста, подождите"
          title="Фид находится в обработке."
          type="warning"
        />
      )}
      {feed?.processing_status === 2 && (
        <MyAlert
          message="Пожалуйста, подождите"
          title="Фид обновляется."
          type="warning"
        />
      )}
      {feed?.processing_status === 4 && (
        <MyAlert
          message="Пожалуйста, подождите"
          title="Подготовка дашборда."
          type="warning"
        />
      )}
      <DeleteModal
        onConfirmDelete={onConfirmRemoveFeed}
        isModalDeleteOpen={isModalDeleteOpen}
        setIsModalDeleteOpen={setIsModalDeleteOpen}
        title="Удалить фид"
        text={`Вы уверены, что хотите удалить фид "${feed?.name}" со всем содержимым?`}
      />
    </div>
  );
};

export default Feed;

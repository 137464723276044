import {Button, Flex, Form, Input, Modal, Select} from "antd";
import React, { useState } from "react";

const AddNewFeed = ({ tableid, addNewFeed, rewriteParentFeed, feedParents }) => {

  const [feedParentId, setFeedParentId] = useState(0);
  const [errorsList, setErrorsList] = useState([{
      error: "Не выбран фид",
  }]);
  const [showErrors, setShowErrors] = useState(false);

  // SAVE NEW FEED
  const [addNewFeedModalVisible, setAddNewFeedModalVisible] = useState(false);
  const [newFeedName, setNewFeedName] = useState('');
  const [loading, setLoading] = useState(false);

  // SAVE FEED
  const [feedModalVisible, setFeedModalVisible] = useState(false);

  // SAVE REWRITE FEED
  const [rewriteFeedModalVisible, setRewriteFeedModalVisible] = useState(false);

  const showModalSaveNewFeed = () => {
    setFeedModalVisible(false);
    setAddNewFeedModalVisible(true);
  };

  const showModalSaveFeed = () => {
    setFeedModalVisible(true);
  };

  const showModalSaveRewriteFeed = () => {
    setFeedModalVisible(false);
    setFeedParentId(0);
    setShowErrors(false);
    setErrorsList([{
        error: "Не выбран фид",
    }]);
    setRewriteFeedModalVisible(true);
  };

  const handleAddNewFeedModalOk = async () => {
    setLoading(true);
    await addNewFeed(tableid, newFeedName).then((response) => {
      console.log(response);
      setAddNewFeedModalVisible(false);
      setNewFeedName('');
      setLoading(false);
    }).catch(() => {
      // Если произошла ошибка, сбросьте состояние загрузки
      setLoading(false);
    });
  };

  const handleRewriteFeedModalOk = async () => {
    if (!errorsList.length) {
        setLoading(true);
        await rewriteParentFeed(feedParentId).then((response) => {
            console.log(response);
            setRewriteFeedModalVisible(false);
            setFeedParentId(0);
            setLoading(false);
        }).catch(() => {
            // Если произошла ошибка, сбросьте состояние загрузки
            setLoading(false);
        });
    } else {
        setShowErrors(true);
    }
  };

  const handleAddNewFeedModalCancel = () => {
    setAddNewFeedModalVisible(false);
  };

  const handleAddFeedModalCancel = () => {
    setFeedModalVisible(false);
  };

  const handleAddRewriteFeedModalCancel = () => {
    setRewriteFeedModalVisible(false);
  };

  const handleChangeParentFeed = (value) => {
      setErrorsList([]);
      setShowErrors(false);
      setFeedParentId(value);
  }

  return (
    <>
      <Button
        className="btn"
        onClick={showModalSaveFeed}

        size="large"
        type="primary"
      >
        Сохранить изменения
      </Button>
      <Modal
        open={addNewFeedModalVisible}
        onOk={handleAddNewFeedModalOk}
        confirmLoading={loading}
        onCancel={handleAddNewFeedModalCancel}
        title="Сохранить новый фид"
      >
        <Form.Item>
          <Input
            value={newFeedName}
            placeholder="Укажите название"
            onChange={(e) => setNewFeedName(e.target.value)}
          />
        </Form.Item>
      </Modal>

      <Modal
        open={rewriteFeedModalVisible}
        onOk={handleRewriteFeedModalOk}
        confirmLoading={loading}
        onCancel={handleAddRewriteFeedModalCancel}
        title="Перезаписать существующий фид"
      >
        {showErrors && (
          <div className="error">
              Не выбран фид
          </div>
        )}
        <div className="errormodal_content ">
          <div className="content">
            <Select
              // mode="tags"
              placeholder="Выберите фид"
              onChange={handleChangeParentFeed}
              style={{
                width: "100%",
              }}
              // value={feedParents.filter((el) => {
              //   return hiddenColumns.columns.includes(el.value);
              // })}
              value={feedParentId === 0 ? 'Выберите фид' : feedParentId}
              defaultValue={[]}
              options={feedParents}
            />
          </div>
        </div>
      </Modal>

      <Modal
          open={feedModalVisible}
          // onOk={handleAddNewFeedModalOk}
          confirmLoading={loading}
          onCancel={handleAddFeedModalCancel}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          title="Создать новый или перезаписать существующий?"
      >
          <Button
              className="btn"
              onClick={showModalSaveNewFeed}

              size="large"
              type="primary"
          >
              Сохранить как новый фид
          </Button>
          <br />
          <Button
              className="btn"
              onClick={showModalSaveRewriteFeed}

              size="large"
              type="primary"
          >
              Перезаписать существующий
          </Button>
      </Modal>
    </>
  );
};

export default AddNewFeed;

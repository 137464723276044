import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Input } from "antd";
const EditableContext = React.createContext(null);
export const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
export const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex] && record[dataIndex].url ? record[dataIndex].url : record[dataIndex],
      });

    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        // сохраняем если есть изменения
        if(record[dataIndex] !== values[dataIndex] && !record[dataIndex].url){
           handleSave(values[dataIndex],record.key,dataIndex);
        }
      } catch (errInfo) {
        console.error("Save failed:", errInfo);
      }
    };
    let childNode = children;

    if (editable && children.toString() !== "," + new Date().toLocaleDateString().toString()) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          // rules={[
          //   {
          //     required: true,
          //     message: `${title} is required.`,
          //   },
          // ]}
        >
            {/*{children.toString() === "," + new Date().toLocaleDateString().toString() ?*/}
            {/*    <div>*/}
            {/*    <Input ref={inputRef} value={children.slice(1)} defaultValue={children.slice(1)} onPressEnter={save} onBlur={save} />*/}
            {/*    </div>*/}
            {/*    :*/}
            {/*    <Input ref={inputRef} onPressEnter={save} onBlur={save} />*/}
            {/*}*/}

            <Input ref={inputRef} onPressEnter={save} onBlur={save} />

        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    } else {
        <div
            className="editable-cell-value-wrap"
            style={{
                paddingRight: 24,
                color: 'red'
            }}
        >
            {children}
        </div>
    }
    return <td {...restProps}><div style={{ paddingLeft: 8 }}>{childNode}</div></td>;
  };

import { Breadcrumb, Col, Row, Table, Flex } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import FeedDashboardOverviewTop from "./FeedDashboardOverviewTop";
import { Link } from "react-router-dom";
import FeedDashboardOverviewStat from "./FeedDashboardOverviewStat";

const columns = [
  {
    title: "Категория",
    dataIndex: "category",
    key: "category",
    width: 300,
    render: (text, record) => {
      // Проверяем, является ли запись родительским элементом
      const isParent = record.children && record.children.length > 0;
      // Применяем жирное начертание для родительских элементов
      const fontWeight = isParent ? "bold" : "normal";

      return (
        <div className="dashboardcontent_category-title" style={{ fontWeight }}>
          {text}
        </div>
      );
    },
  },
  {
    title: "Кол-во подкатегорий+",
    dataIndex: "subcategories_count",
    key: "subcategories_count",
  },
  {
    title: "Кол-во предложений+",
    dataIndex: "offers_count",
    key: "offers_count",
  },
  {
    title: "Минимальная цена+",
    dataIndex: "min_price",
    key: "min_price",
  },
  {
    title: "Средняя цена+",
    dataIndex: "avg_price",
    key: "avg_price",
  },
  {
    title: "Максимальная цена+",
    dataIndex: "max_price",
    key: "max_price",
  },
  {
    title: "Длина Description+",
    dataIndex: "description_len",
    key: "description_len",
  },
  {
    title: "Кол-во изображений",
    dataIndex: "pictures_count",
    key: "pictures_count",
  },
  {
    title: "Кол-во вложенных тегов param",
    dataIndex: "params_count",
    key: "params_count",
  },
  // {
  //   title: "Кол-во вложенных тегов vf_param",
  //   dataIndex: "vfparamcount",
  //   key: "vfparamcount",
  // },
];

const FeedDashboardContent = ({
  feed,
  handleRemoveFeed,
  feedContent,
  dashboard,
}) => {
  return (
    <>
      <Row>
        <Col span={10}>
          <Breadcrumb
            items={[
              {
                title: <HomeOutlined />,
              },
              {
                title: <Link to={`/feeds/${feed.id}`}>{feed.name}</Link>,
              },
              {
                title: "Содержание фида",
              },
            ]}
          />
        </Col>
        <Col span={14}>
          <ul className="DashboardMenu">
            <li className="">
              <Link to={`/feeds/${feed.id}`}>Главная</Link>
            </li>
            <li className="">
              <Link to={`/feeds/${feed.id}/errors`}>Отчет об ошибках</Link>
            </li>
            <li className="">
              <Link to={`/feeds/${feed.id}/structure`}>Структура фида</Link>
            </li>
            <li className="active">
              <Link to={`/feeds/${feed.id}/content`}>Содержание фида</Link>
            </li>
          </ul>
        </Col>
      </Row>
      <h1>Содержание фида</h1>
      <FeedDashboardOverviewTop
        feed={feed}
        feedupd={dashboard?.updated_at}
        handleRemoveFeed={handleRemoveFeed}
      />

      <Col span={13}>
        <div className="feeddashboardcard">
          <div className="feeddashboardcard-content">
            <Flex gap={20} justify="space-between">
              <FeedDashboardOverviewStat
                precision={0}
                showall={true}
                value={dashboard?.offers_count || 0}
                desc="Всего предложений"
              />
              <FeedDashboardOverviewStat
                precision={0}
                value={dashboard?.categories_count || 0}
                desc="Категорий"
              />
              <FeedDashboardOverviewStat
                precision={0}
                value={dashboard?.subcategories_count || 0}
                desc="Подкатегорий"
              />
              <FeedDashboardOverviewStat
                precision={2}
                suffix="тыс."
                value={dashboard?.min_price || 0}
                desc="Минимальная цена"
              />
              <FeedDashboardOverviewStat
                precision={2}
                suffix="млн."
                value={dashboard?.max_price || 0}
                desc="Максимальная цена"
              />
            </Flex>
          </div>
        </div>
      </Col>

      <h2
        className="FeedDashboardStructureshowTagsTitle"
        style={{ marginBottom: 30 }}
      >
        Разделение данных в фиде
      </h2>

      <Table
        className="dashboardcontent table"
        columns={columns}
        bordered
        pagination={false}
        dataSource={feedContent}
        scroll={{ x: columns.length * 270, y: "70vh" }}
      />
    </>
  );
};

export default FeedDashboardContent;

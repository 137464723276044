import React, { useState, useContext, useEffect } from "react";
import { FolderOutlined, PlusOutlined } from "@ant-design/icons";
import SidebarMenuParent from "./SidebarMenuParent";
import { Context } from "../../../index";
import AddNewProject from "./AddProject/AddNewProject";
import SidebarMenu from "./SidebarMenu";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const SidebarMenuNew = ({openKeys,handleOpenChange,setOpenMenuItem,openMenuItem}) => {
  const { store } = useContext(Context);
  const navigate = useNavigate();
  const handleSubLevelClicked = (e,menuid,tableid) => {
    e.stopPropagation();
    setOpenMenuItem((prev)=>{
      return [...prev,menuid ]
    });
    navigate(`/feeds/${tableid}`);

  }


  const prepareProjects = (items) => {
    let trst = [];
    if (items.length > 0) {
      trst = items.map((item) => {
        let tables = [];
        if (item.tables && item.tables.length > 0) {
          tables = item.tables.map((table) => {
            let childrens;
            if(table.children && table.children.length > 0){
              childrens = table.children.map((child)=>{
                return getItem(child.name, `table_${child.id}`,);
              })
              return getItem(<div onClick={(e)=>handleSubLevelClicked(e,`table_${table.id}`,table.id)}>{table.name}</div>, `table_${table.id}`, null, childrens);
            }
            else{
              return getItem(table.name, `table_${table.id}`);
            }
          });
        }
        tables.push(
          getItem(
            <span className="addNewFeedBtn">
              <PlusOutlined />
              Добавить фид{" "}
            </span>,
            `newfeed_${item.id}`
          )
        );

        return getItem(
          <SidebarMenuParent
            title={item.name}
            id={item.id}
      
            deleteProject={store.deleteProject}
            updateProject={store.updateProject}
          />,
          `parent_${item.id}`,
          <FolderOutlined />,
          tables
        );
      });
    }
    return trst;
  };


  const [projects, setProjects] = useState([]);



  useEffect(() => {
    store.getProjects()
        // eslint-disable-next-line
  },[]);
    // eslint-disable-next-line
  const [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    setProjects(prepareProjects(store.getStateProjects())); 
    setForceUpdate((prev) => prev + 1);
        // eslint-disable-next-line
  }, [store.projects]);

  const addNewProject = (newProjectName) => {
    return store.addProject(newProjectName);
  };

  // useEffect(() => {
  //   console.log(store.currentFeedData);
  // }, [store.currentFeedData]);
 




  return (
    <>

    {/* {forceUpdate} */}
    
      <div className="myProjectsTitle">Мои проекты</div>
      <SidebarMenu
        projects={projects}
        handleOpenChange={handleOpenChange}
        openKeys={openKeys}
        setOpenMenuItem={setOpenMenuItem}
        openMenuItem={openMenuItem}
        currentFeedData={store.currentFeedData}
      />
      <AddNewProject
        addNewProject={addNewProject}
        currentFeedData={store.currentFeedData}
      />
    </>
  );
};

export default observer(SidebarMenuNew);

import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const type = 'DraggableHeader';

const DraggableHeaderCell = ({ index, moveColumn, className, children, ...restProps }) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveColumn(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <th
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      {...restProps}
    >
      {children}
    </th>
  );
};

export default DraggableHeaderCell;

import { Menu, Modal } from "antd";
import {  useState } from "react";
import { useNavigate } from "react-router-dom";
import AddNewFeed from "./AddProject/AddNewFeed";

const SidebarMenu = ({ projects, openKeys,handleOpenChange,setOpenMenuItem,openMenuItem }) => {
  const navigate = useNavigate();
  const [parent, setParent] = useState(0);
  const [modalVisible, setModalVisible] = useState(false); // Состояние для отображения модального окна
  // eslint-disable-next-line
  const [modalItemId, setModalItemId] = useState(null); // Состояние для передачи ID в модальное окно

  // useEffect(() => {
  //   if (parent) {
  //     setOpenKeys((prev) => {
  //       const newKey = `parent_${parent}`;
  //       if (!prev.includes(newKey)) {
  //         return [...prev, newKey];
  //       }
  //       return prev;
  //     });
  //   }
  // }, [parent]);




  const onMenuItemClick = (item) => {
    if (item) {
      const [route, id] = item.key.split("_");
      if (route === "table") {
        navigate(`/feeds/${id}`);
        setOpenMenuItem([item.key])
        
      } else if (route === "newfeed") {
        setParent(id);
        // Показываем модальное окно и передаем ID
        setModalItemId(id);
        setModalVisible(true);
      }
    }
  };

  // Обработчик для закрытия модального окна
  const handleModalCancel = () => {
    setModalVisible(false);
  };

  // Обработчик для подтверждения в модальном окне
  const handleModalConfirm = () => {
    // Добавьте здесь логику для подтверждения
    // Можно использовать ID из modalItemId
    setModalVisible(false);
  };
  const handleMenuItemSelect = (d) => {
    console.log(d,'ddadadad');
    // setOpenKeys(d)
  }


  return (
    <div onClick={(e) => e.stopPropagation()}>
      
      <Menu
        className="sidebarmenu"
        mode="inline"
        openKeys={openKeys}
        style={{ border: "0", backgroundColor: "rgba(250, 250, 250, 1)" }}
        onOpenChange={(d)=>{handleOpenChange(d)}}
        items={projects}
        onClick={onMenuItemClick}
        selectedKeys={openMenuItem}
        onSelect={(d)=>{handleMenuItemSelect(d)}}
      ></Menu>
      {/* Модальное окно */}
      <Modal
        title="Добавление проекта"
        open={modalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalConfirm}
        footer={null}
      >
        <>
       
        <AddNewFeed parent={parent} handleModalCancel={handleModalCancel}/>
        </>
      </Modal>
    </div>
  );
};

export default SidebarMenu;

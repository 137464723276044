import { Button, Image, Modal } from "antd";
import LazyLoad from "react-lazyload"; // Импортируем компонент LazyLoad

import { EyeOutlined } from "@ant-design/icons";
import RenderColumnTitle from "../components/Feed/RenderColumnTitle";
import { Badge } from "antd/lib";
import { useState } from "react";
import { LinkOutlined } from '@ant-design/icons';
// DTO Колонки
export const prepareColumns = (
  columns,
  showModalEdit,
  showModalDelete,
  hiddenColumns,
  isShowParams
) => {
  // Объект с функциями рендеринга для разных типов данных
  const renders = {
    picture: (pictures) => {
      if (!pictures) {
        return null;
      }
      return (
        <div className="tableimgthumbWrapper">
          <Image.PreviewGroup>
            {pictures.map((el, index) => (
              <div className="tableimgthumb" key={index}>
                <LazyLoad height={40} offset={100}>
                  <Image
                    width={40}
                    src={el}
                    preview={{
                      mask: (
                        <div className="custom-mask">
                          <EyeOutlined />
                        </div>
                      ),
                    }}
                  />
                </LazyLoad>
              </div>
            ))}
          </Image.PreviewGroup>
        </div>
      );
    },
    text: (text) => {
      // return text ? `${text}` : "";
      return `${text}` === "currentDate" ? `${new Date().toLocaleDateString()}` : `${text}`;
    },
    url: (url) => {
      if (!url?.url) {
        return (
          <>
            <Badge status="warning" /> - {url}
          </>
        );
      } else {
        if (url.status === 200) {
          return (
            <>
              <Badge status="success" /> {url.status} {url.url} <a target="_blank"  href={url.url} style={{ color: "#a3a3a3" }}><LinkOutlined /></a>
            </>
          );
        }
        if (url.status === 404) {
          return (
            <>
              <Badge status="error" /> {url.status} {url.url} <a target="_blank"  href={url.url} style={{ color: "#a3a3a3" }}><LinkOutlined /></a>
            </>
          );
        } else {
          return (
            <>
              <Badge status="warning" /> {url.status} {url.url} <a target="_blank"  href={url.url} style={{ color: "#a3a3a3" }}><LinkOutlined /></a>
            </>
          );
        }
      }
    },
    number: (text) => {
      if (text || text === 0) {
        return `${text}`;
      }
      return "";
    },
    category: (cat) => {
      if (!cat?.title) {
        return cat ? `${cat}` : "";
      } else {
        return cat.title;
      }
    },
  };

  let filtercolumns = columns.filter((column) => {
    return (
      !column.attr_name.startsWith("param") && // Проверка на начало строки с "param"
      !hiddenColumns.includes(column.id) // Проверка на скрытые колонки
    );

  });

  filtercolumns = filtercolumns.map((column) => {
    const renderFunction = renders[column.type.name];
    const renderFunctionZoomos = (text) => {
      if (text) {
        return <span style={{ paddingLeft: 14 }}>{text}</span>;
      } else {
        return "";
      }
    };

    return {
      title: column.for_cache ? (
        <RenderColumnTitle
          formula={column.formula}
          name={column.name}
          dataIndex={column.id}
          column={column.id}
          showModalEdit={showModalEdit}
          showModalDelete={showModalDelete}
          editable={true}
          empty={column.empty}
        />
      ) : (
        (
          <RenderColumnTitle
            name={column.name}
            dataIndex={column.id}
            column={column.id}
            editable={false}
            empty={column.empty}
          />
        )
      ),
      ctitle: column.name,
      hidden:
        column.attr_name === "param" ||
        column.attr_name === "param~name" ||
        column.attr_name === "param~value",
      for_cache: column.for_cache,
      attr_name: column.attr_name,
      dataIndex: column.id,
      key: `render+${column.id}`,
      width: 100,
      columntype: column.type.name,
      showSorterTooltip: false,
      render:
        column.attr_name === "zoomos" ? renderFunctionZoomos : renderFunction, // Задаем функцию рендеринга
      metadata: column.metadata,
    };
  });
  if (isShowParams) {
  filtercolumns.push({
    title: "Параметры",
    ctitle: "Параметры",
    dataIndex: "md_param",
    for_cache: "false",
    key: `render+md_param`,
    width: 100,
    editable: false,
  });
}

  return filtercolumns;
};

// DTO Строки
export const prepareRows = (rows, cols) => {
  const colMap = cols.reduce((acc, col) => {
    acc[col.id] = col;
    return acc;
  }, {});

  return rows.map((row) => {
    const rowObj = {
      key: row.id,
      id: row.id,
    };

    const paramrows = row.values.reduce((acc, { column, data }) => {
      if (
        colMap[column] &&
        colMap[column].attr_name !== "param" &&
        colMap[column].attr_name !== "param~name" &&
        colMap[column].attr_name !== "param~value"
      ) {
        rowObj[column] = data;
      } else {
        const colname = colMap[column]?.name;
        if (colname) {
          acc.push({ name: colname, value: data });
        }
      }
      return acc;
    }, []);
    if (paramrows.length > 0) {
      // console.log(paramrows,paramrows.length,'paramrows')
      rowObj["md_param"] = <TableRow rowData={paramrows} />;
    }
    return rowObj;
  });
};

const TableRow = ({ rowData }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Button style={{ marginLeft: "10%", width: "80%" }} onClick={showModal}>
        Показать
      </Button>
      <Modal
        title=""
        footer={null}
        width={570}
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {rowData.map((row, index) => (
          <div key={index}>
            <strong>{row.name}</strong>: <span>{row.value}</span>
          </div>
        ))}
      </Modal>
    </>
  );
};

import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';

const AutoCompleteFormula = ({ values, inputValue, setInputValue, errFormula, setErrFormula }) => {
  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    const match = value.match(/\$\w*$/);
    if (match && match[0].startsWith('$')) {
      const searchQuery = match[0].substring(1).toLowerCase(); // Извлекаем текст после $ и приводим к нижнему регистру

      const filteredValues = searchQuery === ''
        ? values
        : values.filter(variable => variable.label.toLowerCase().startsWith(searchQuery));

      setOptions(filteredValues.map(variable => ({
        value: variable.label, // Используем label в качестве значения опции
        label: variable.label
      })));
      ///console.log('values: ', values);
    } else {
      setOptions([]);
    }
  };

  const handleSelect = (value) => {
    console.log('value', value);
    const lastDollarIndex = inputValue.lastIndexOf('$');
    const newValue = inputValue.slice(0, lastDollarIndex) + `$"${value}"`;
    setInputValue(newValue);
    setOptions([]);
  };

  const handleChange = (value) => {
    setErrFormula('');
    console.log('value', value);
    setInputValue(value);
    handleSearch(value);
  };

  return (
    <>
      <div className='infoblock'>
        <h3>Примеры формул</h3>
        <p>$"price" - $"oldprice"</p>
        <p>$"price" {"<"} $"oldprice"</p>
        <p>($"price" / $"zoomos_price") * 100 - 100</p>
        <p>$"price" & " " $"Title"</p>
        <p>$"Title" & " " $"Description"</p>
        <p>любой текст без кавычек</p>
      </div>

    <AutoComplete
      options={options}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder="Укажите формулу"
      onChange={handleChange}
      value={inputValue}
      style={{ width: '100%' }}
    >
      <Input
      />
    {errFormula && <div style={{ color: "red" }}>{errFormula}</div>}
    </AutoComplete></>
  );
};

export default AutoCompleteFormula;

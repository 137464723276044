import { Button, Input,  Modal } from "antd";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
const AddNewProject = ({addNewProject}) => {


    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      AddProject();
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const [loading, setLoading] = useState(false);
  
  
    const [newProjectName, setNewProjectName] = useState("");
  
    const AddProject = async () => {
      setLoading(true)
      await addNewProject(newProjectName).then((resp) => {
        if (resp) {
          setNewProjectName("");
        }
      });
      setLoading(false)
    };

  return (
    <>
      <button
        onClick={showModal}
        className="myProjectsButton"
        style={{ marginLeft: "20px", marginTop: 30 }}
      >
        <span className="myProjectsButton-text">Добавить проект</span>{" "}
        <PlusOutlined style={{ fontSize: "18px" }} />
      </button>
      <Modal
        title="Создать проект"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Создать проект
          </Button>,
        ]}
      >
        <Input
          placeholder="Название проекта"
          value={newProjectName}
          onChange={(e) => {
            setNewProjectName(e.target.value);
          }}
        />
      </Modal>
    </>
  );
};
export default AddNewProject

import React, { useContext, useEffect, useState } from "react";
import { Button, message, Steps } from "antd";
import { useNavigate } from "react-router-dom";

import { Context } from "../../../..";
import AddProjectTableMainFields from "./AddProjectTableMainFields";
import AddProjectTableFile from "./AddProjectTableFile";

const AddNewFeed = ({ parent, handleModalCancel }) => {
  const { store } = useContext(Context);

  // const { token } = theme.useToken();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);

  const [projectName, setProjectName] = useState("");
  const [projectMode, setProjectMode] = useState("");
  const [projectUrl, setProjectUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [projectFile, setProjectFile] = useState(null);

  const [feedTypes, setFeedTypes] = useState([]);
  const [selectedFeedType, setSelectedFeedType] = useState('');

  useEffect(() => {
    setIsLoading(true);
    store.getFeedTypes().then((response) => {
      setFeedTypes(
        response.data.map((el) => ({
          label: el.name,
          value: el.id,
        }))
      );
      setIsLoading(false);
    });
  }, []);

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const handleNameChange = (value) => {
    setProjectName(value.currentTarget.value);
  };
  const handleSelectChange = (value) => {
    setProjectMode(value);
  };

  const handleUrlChange = (value) => {
    setProjectUrl(value.currentTarget.value);
  };
  const handleFileChange = (file) => {
    setProjectFile(file);
  };
  const submitNewFeed = async () => {
    setIsLoading(true);
    try {
      const response = await store.submitNewFeed(
        parent,
        projectName,
        projectMode,
        projectUrl,
        projectFile,
        selectedFeedType
      );
      if (response.status === 201) {
        setProjectName("");
        setProjectMode("");
        setProjectUrl("");
        setSelectedFeedType("");
        setProjectFile(null);
        handleModalCancel();
        setCurrent(0);
        message.success("Фид успешно создан");
        navigate(`/feeds/${response.data.id}`);
      } else if (response.status === 422) {
        message.error(response.detail);
      } else {
        message.error(response.statusText);
      }
    } catch (error) {
      console.error("Error submitting new feed:", error);
      message.error("Произошла ошибка при создании фида. Попробуйте снова.");
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [
    {
      title: "Настройка",
      content: (
        <AddProjectTableMainFields
          selectedFeedType={selectedFeedType}
          setSelectedFeedType={setSelectedFeedType}
          feedTypes={feedTypes}
          handleSelectChange={handleSelectChange}
          modeVals={[
            { label: "Необновляемый источник", value: 1 },
            { label: "Обновляемый источник", value: 2 },
          ]}
          selectValue={projectMode}
          handleNameChange={handleNameChange}
          projectName={projectName}
        />
      ),
    },
    {
      title: "Загрузка",
      content: (
        <AddProjectTableFile
          mode={projectMode}
          projectUrl={projectUrl}
          fileChange={handleFileChange}
          urlChange={handleUrlChange}
          feedType={selectedFeedType}
        />
      ),
    },
    // {
    //   title: "Готово",
    //   content: "Last-content",
    // },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  return (
    <div className="stepsWrapper">
      <Steps current={current} items={items} />

      <div>{steps[current].content} </div>

      <div className="stepsBtn">
        {current === 0 && projectName !== "" && projectMode !== "" && selectedFeedType !== "" && (
          <Button type="primary" onClick={() => next()}>
            Дальше
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Назад
          </Button>
        )}
        {current === steps.length - 1 &&
          projectName !== "" &&
          (projectUrl !== "" || projectFile != null) && (
            <Button
              type="primary"
              loading={isLoading}
              onClick={() => {
                submitNewFeed();
              }}
            >
              Добавить
            </Button>
          )}
      </div>
    </div>
  );
};

export default AddNewFeed;

import React, { useEffect, useState } from "react";
import { Form, Select, Input, Button, Space, Flex } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const types = [
  { value: "before", label: "Извлечь текст перед разделителем" },
  { value: "after", label: "Извлечь текст после разделителя" },
  { value: "between", label: "Извлечь текст между разделителями" },
];
const relative = [
  { value: "start", label: "От начала строки" },
  { value: "end", label: "От конца строки" },
];

const FormComponent = ({ columns,formExtractState, setFormExtractState }) => {
  const [form] = Form.useForm();


  useEffect(() => {
    console.log("FormExtractState updated:", formExtractState);
  }, [formExtractState]);

  const handleExtractFormChange = (field, value) => {
    setFormExtractState((prev) => {
      const { errors, ...rest } = prev;
      return {
        ...rest,
        [field]: value,
        errors: {
          // ...errors,
          // [field]: null
        }
      };
    });
    
  };
  const filterOption = (input, option) => {
    // console.log(input, option.children, option.children.toLowerCase().includes(input.toLowerCase()))
    return option.children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item placeholder="Выберите столбец" label="Выберите столбец">
        <Select
          placeholder="Выберите столбец"
          // value={elseValue}
          // onChange={handleElseChange}
          style={{ width: "100%" }}
          showSearch
          filterOption={filterOption}
          allowClear
          value={formExtractState.column}
          onChange={(value) => handleExtractFormChange("column", value)}
        >
          {columns.map((col) => (
            <Option key={col.value} value={col.value}>
              {col.label}
            </Option>
          ))}
        </Select>
        {formExtractState?.errors?.column && <div className='err' style={{ color: 'red' }}>{formExtractState.errors['column']}</div>}
      </Form.Item>
      <Form.Item placeholder="Выберите направление извлечения" label="Выберите направление извлечения">
        <Select
          placeholder="Выберите направление извлечения"
          style={{ width: "100%" }} 
          value={formExtractState.relative}
          defaultValue={relative[0]}
          onChange={(value) => handleExtractFormChange("relative", value)}
        >
          {relative.map((type) => (
            <Option key={type.value} value={type.value}>
              {type.label}
            </Option>
          ))}
        </Select>
        {formExtractState?.errors?.relative && <div className='err' style={{ color: 'red' }}>{formExtractState.errors['relative']}</div>}
      </Form.Item>
      <Form.Item placeholder="Выберите тип извлечения" label="Выберите тип извлечения">
        <Select
          placeholder="Выберите тип извлечения"
          style={{ width: "100%" }} 
          value={formExtractState.type}
          onChange={(value) => handleExtractFormChange("type", value)}
        >
          {types.map((type) => (
            <Option key={type.value} value={type.value}>
              {type.label}
            </Option>
          ))}
        </Select>
        {formExtractState?.errors?.type && <div className='err' style={{ color: 'red' }}>{formExtractState.errors['type']}</div>}
      </Form.Item>
      {formExtractState.type === "before" && (
        <div
        className='infoblock'
        >
          <h3>Пример работы</h3>
          <p> Указываем в качестве разделителя "?utm_source" </p>
          <p>
            {" "}
            Красная часть остается, так как перед разделителем "https://site.ru"
          </p>
          <span style={{ backgroundColor: "#ff9d9d", padding: 2 }}>
            https://site.ru
          </span>
          <span style={{ backgroundColor: "#d0e6d6", padding: 2 }}>
            ?utm_source=yandex&utm_medium=cpc&utm_campaign=center_feeds
          </span>{" "}
          <p>
            После применения получает новый столбик со следующим значением:{" "}
            <strong>https://site.ru</strong>
          </p>
          <p></p>
        </div>
      )}
      {formExtractState.type === "after" && (
        <div
        className='infoblock'
        >
          <h3>Пример работы</h3>
          <p> Указываем в качестве разделителя "?utm_source" </p>
          <p>
            {" "}
            Зелёная часть остается, так как после разделителя
            "=yandex&utm_medium=cpc&utm_campaign=center_feeds"
          </p>
          <span style={{ backgroundColor: "#ff9d9d", padding: 2 }}>
            https://site.ru
          </span>
          <span style={{ backgroundColor: "#d0e6d6", padding: 2 }}>
            ?utm_source=yandex&utm_medium=cpc&utm_campaign=center_feeds
          </span>{" "}
          <p>
            После применения получает новый столбик со следующим значением:{" "}
            <strong>=yandex&utm_medium=cpc&utm_campaign=center_feeds</strong>
          </p>
          <p></p>
        </div>
      )}
      {formExtractState.type === "between" && (
        <div
        className='infoblock'
        >
          <h3>Пример работы</h3>
          <p>Указываем первый разделитель - "https://" </p>
          <p>Указываем второй разделитель - "?utm_source" </p>
          <p>
          Остается только домен из ссылки
          </p>
          <span style={{ backgroundColor: "#ff9d9d", padding: 2 }}>
            https://
          </span>
          <span style={{ backgroundColor: "#d0e6d6", padding: 2 }}>
          site.ru
          </span>
          <span style={{ backgroundColor: "#ff9d9d", padding: 2 }}>
          ?utm_source=yandex&utm_medium=cpc&utm_campaign=center_feeds
          </span>
          <p>
            После применения получает новый столбик со следующим значением:{" "}
            <strong>site.ru</strong>
          </p>
          <p></p>
        </div>
      )}

      {(formExtractState.type === "before" || formExtractState.type === "between") && (
        <Form.Item
          placeholder="Извлечение от начала строки"
          label="Извлечение от начала строки"
        >
          <Input
            value={formExtractState.before}
            onChange={(e) => handleExtractFormChange("before", e.target.value)}
            placeholder="Извлечение от начала строки"
          />
           {formExtractState?.errors?.before && <div className='err' style={{ color: 'red' }}>{formExtractState.errors['before']}</div>}
        </Form.Item>
      )}
      {(formExtractState.type === "after" || formExtractState.type === "between") && (
        <Form.Item
          placeholder="Извлечение от конца строки"
          label="Извлечение от конца строки"
        >
          <Input
            value={formExtractState.after}
            onChange={(e) => handleExtractFormChange("after", e.target.value)}
            placeholder="Извлечение от конца строки"
          />
           {formExtractState?.errors?.after && <div className='err' style={{ color: 'red' }}>{formExtractState.errors['after']}</div>}
        </Form.Item>
      )}
      {/* {JSON.stringify(formExtractState)} */}
    </Form>
  );
};

const ExtractStringForm = ({ columns,formExtractState, setFormExtractState }) => {
  
  return (
    <div style={{ padding: "20px" }}>
      <FormComponent columns={columns} formExtractState={formExtractState} setFormExtractState={setFormExtractState} />
    </div>
  );
};

export default ExtractStringForm;

import $api from "../http";

export default class AuthService {
    static async login(username, password) {
        return $api.post('/users/login', {username, password})
    }

    static async registration(username,email,password,confirm_password) {
        return $api.post('/users/register', {username,email,password,confirm_password})
    }
    static async refresh() {
        return $api.post('/users/refresh')
    }
    static async authme() {
        return $api.get('/users/me')
    }

    static async logout() {
        return $api.post('/users/logout')
    }

    static async addproject(name) {
        return $api.post('/addproject', {name})
    }
    static async removeproject(id) {
        return $api.post('/removeproject', {id})
    }

    static async uploadCsv(formData) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        };
    
        const response = await $api.post('/uploadcsv', formData, config);
        return response.data;
    }

    static async projects() {
        return $api.get('/projects')
    }
    static async getProjectById(id) {
        return $api.get('/getprojectbyid', { params: { id } });
    }
    static async setProjectTemplate(id, name){
        return $api.post('/setprojecttemplate', { id, name });
    }
    static async setProjectTemplateSettings(id, data){
        return $api.post('/setprojecttemplatesettings', { id, data });
    }
    static async startRender(id){
        return $api.post('/startrender', { id });
    }
    static async getTaskLogById(id) {
        return $api.get('/gettasklogbyid', { params: { id } });
    }

}


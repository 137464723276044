import {  Flex, Table } from "antd";
import { useEffect } from "react";
import ZoomosForm from "./ZoomosForm";
import GoogleForm from "./GoogleForm";

const columns = [
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Дата обновления",
    dataIndex: "update",
    key: "update",
    render: (text) => {
      if (text[0]) {
        const date = new Date(Date.parse(text[0])); // Создаем объект Date
        return date.toLocaleDateString("ru-RU", {
          weekday: "long", // день недели полностью
          year: "numeric", // числовое представление года
          month: "long", // месяц полностью
          day: "numeric", // числовое представление дня месяца
        });
      } else {
        const date = new Date(Date.parse(text[1])); // Создаем объект Date
        return date.toLocaleDateString("ru-RU", {
          weekday: "long", // день недели полностью
          year: "numeric", // числовое представление года
          month: "long", // месяц полностью
          day: "numeric", // числовое представление дня месяца
        });
      }
    },
  },
  {
    title: "Тип интеграции",
    dataIndex: "type",
    key: "type",
    width: 200,
    render: (text) => {
      switch (text) {
        case 1:
          return <Flex align="center" gap={10} ><img src="/zoomos.png" style={{width: 20}} alt="zoomos" /> Zoomos</Flex>;
        case 2:
           return <Flex align="center" gap={10} ><img src="/gsheets.png" style={{width: 20}} alt="zoomos" /> Google Sheets</Flex>;
        default:
          return "Неизвестно";
      }
    }
  },
  {
    title: "В фидах",
    dataIndex: "infeeds",
    key: "infeeds",
  },
];

const Zoomos = ({
  showIntegrations,
  setShowIntegrations,
  feedColumns,
  getIntegrations,
  getIntegration,
  projectId,
  tablerows,
  closeModal,
  submitModal,
  addColumnWithType,
  ZoomosData,
  setZoomosData,
  selectColumns,
  errTitle,
  clearErrTitle,
  integrationSelected
}) => {


 useEffect(()=>{
  console.log(ZoomosData)
 },[ZoomosData])

  
 
  const [integration_type, integration_id] = integrationSelected;
  return (
    <>
      
 

      {showIntegrations && (
        <Table dataSource={ZoomosData} columns={columns} bordered />
      )}
      {(!showIntegrations && integration_type===1) && (
        <ZoomosForm
        integration_id={integration_id}
        integration_type={integration_type}
        getIntegration={getIntegration}
        tablerows={tablerows}
        errTitle={errTitle}
        clearErrTitle={clearErrTitle}
        closeModal={closeModal}
        submitModal={submitModal}
        selectColumns={selectColumns}
          feedColumns={feedColumns}
          integrationSelected={integrationSelected}
        />
      )}
      {(!showIntegrations && integration_type===2) && (
        <GoogleForm
        integration_id={integration_id}
        integration_type={integration_type}
        getIntegration={getIntegration}
        tablerows={tablerows}
        clearErrTitle={clearErrTitle}
        closeModal={closeModal}
        errTitle={errTitle}
        submitModal={submitModal}
        selectColumns={selectColumns}
          feedColumns={feedColumns}
          integrationSelected={integrationSelected}
        />
      )}
    </>
  );
};
export default Zoomos;

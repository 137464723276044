import { ConfigProvider } from "antd";
import ru_RU from "antd/locale/ru_RU";

import "./styles/app.scss";
import "./styles/ant.scss";
import "./styles/layout.scss";
import "./styles/ui.scss";
import "./styles/feeddashboard.scss";
import "./styles/sidebar.scss";
import "./styles/myprojects.scss";
import AppRouter from "./routes/AppRouter";

const App = () => {
  return (
    <>
      <ConfigProvider
      // button={{ className: 'btn' }}
        theme={{
          token: {
            colorPrimary: "#4A5EFF",
            borderRadius: 4,
          },
          components: {
            Input: {
              borderRadius: 3,
            },

          },
        }}
        locale={ru_RU}
      >
        <AppRouter />
      </ConfigProvider>
    </>
  );
};

export default App;

import React, { useEffect } from "react";
import { Button, Modal, Form, Input } from "antd";
import DeleteModal from "../ui/Modals/DeleteModal";

const ModalsIntegration = ({
  setPopoverVisible,
  setModalsVisible,
  modalsVisible,
  addIntegration,
  updateIntegration,
  deleteIntegration,
  integrationid,
  updateIntegrations,
  clickedRow,
  currentIntegration,
  setCurrentIntegration
}) => {
  const [form] = Form.useForm(); // Инициализация формы
  const [formEdit] = Form.useForm(); // Инициализация формы

  // Эффект для обновления формы при изменении clickedRow
  useEffect(() => {
    console.log(clickedRow);
    if (modalsVisible.editModalVisible) {
      // Обновляем значения формы, когда модальное окно становится видимым и clickedRow изменяется
      formEdit.setFieldsValue({
        name: clickedRow?.name,
        token: clickedRow?.data.api_key,
        feedUrl: clickedRow?.data.zoomos_feed,
        url: clickedRow?.data.url,
      });
    }
  }, [clickedRow, modalsVisible.editModalVisible, formEdit]);

  const handleEditModalOk = (e) => {
    e.stopPropagation();
    const checkform = formEdit.getFieldsValue();

    // Функция для приведения значений к общему виду (замена null на пустую строку)
    const normalize = (value) => value === null ? '' : value;

    // Приводим значения к общему виду
    const formData = {
      name: normalize(checkform.name),
      token: normalize(checkform.token),
      feedUrl: normalize(checkform.feedUrl),
      url: normalize(checkform.url)
    };

    const originalData = {
      name: normalize(clickedRow?.name),
      token: normalize(clickedRow?.data.api_key),
      feedUrl: normalize(clickedRow?.data.zoomos_feed),
      url: normalize(clickedRow?.data.url)
    };

    let shouldSubmit = false;

    if (clickedRow?.type === 1) {
      shouldSubmit = originalData.name !== formData.name ||
                     originalData.token !== formData.token ||
                     originalData.feedUrl !== formData.feedUrl;
    }

    if (clickedRow?.type === 2) {
      shouldSubmit = originalData.name !== formData.name ||
                     originalData.url !== formData.url;
    }

    if (shouldSubmit) {
      // console.log('submit');
      formEdit.submit(); // Сабмит формы
    }
    else{
      setModalsVisible((prev) => ({
        ...prev,
        editModalVisible: false,
      }));
    }
  };


  const handleEditModalCancel = (e) => {
    e.stopPropagation();
    formEdit.resetFields();
    setModalsVisible((prev) => ({
      ...prev,
      editModalVisible: false,
    }));
  };

  const handleDeleteModalOk = async () => {
    // Здесь может быть логика удаления элемента
    await deleteIntegration(clickedRow.key).then(() => {
      setModalsVisible((prev) => ({
        ...prev,
        deleteModalVisible: false,
      }));
      updateIntegrations();
    });
  };

  const setDeleteModalVisible = (state) => {
    setModalsVisible((prev) => ({
      ...prev,
      deleteModalVisible: state,
    }));
  };

  const handleOk = () => {
    form.submit(); // Сабмит формы

  };

  const handleCancel = () => {
    setCurrentIntegration(null)
    setModalsVisible((prev) => ({
      ...prev,
      isModalVisible: false,
    }));

    form.resetFields();
  };

  const onFinish = async (values) => {
    const data = {
      api_key: values.token,
      zoomos_feed: values.feedUrl || null,
    };

    const response = await addIntegration(integrationid, values.name, currentIntegration, data);
    if (response.status === 201) {
      setModalsVisible((prev) => ({
        ...prev,
        isModalVisible: false,
      }));
      updateIntegrations();
      form.resetFields();
      setCurrentIntegration(null)
    } else if (response.status === 422) {
      const currentFields = form.getFieldsValue();
      form.setFields([
        ...Object.keys(currentFields).map(key => ({
          name: key,
          value: currentFields[key]
        })),
        {
          name: 'token',
          //errors: ['Такая интеграция уже добавлена!'],
          errors: [response.data.detail],
        },
      ]);
    }
  };

  const onFinishEdit = async (values) => {
    let data = {}
    if(currentIntegration === "1"){
      data = {
        api_key: values.token,
        zoomos_feed: values.feedUrl || null,
      };
    }else{
      data = {
        url: values.url,
      };
    }

    const response = await updateIntegration(clickedRow.key, values.name, data, currentIntegration);

    if (response.status === 200) {
      setModalsVisible((prev) => ({
        ...prev,
        editModalVisible: false,
      }));
      updateIntegrations();
      formEdit.resetFields();
    } else if (response.status === 422) {
      const currentFields = formEdit.getFieldsValue();
      formEdit.setFields([
        ...Object.keys(currentFields).map(key => ({
          name: key,
          value: currentFields[key]
        })),
        {
          name: 'token',
          errors: ['Такая интеграция уже добавлена!'],
        },
      ]);
    }
  };


  return (
    <>
      <Modal
        title="Добавить интеграцию"
        open={modalsVisible.isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Сохранить
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="integrationForm"
          onFinish={onFinish}
          initialValues={{ remember: false }}
          autoComplete="off"
          style={{ marginTop: 20 }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название интеграции!",
              },
            ]}
          >
            <Input placeholder="Название интеграции" />
          </Form.Item>
          <Form.Item
            name="token"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите API токен аккаунта!",
              },
            ]}
          >
            <Input placeholder={currentIntegration === "1"?"API токен аккаунта":"Ссылка на публичную таблицу в Google Sheets"} />
          </Form.Item>
          {currentIntegration === "1" && <Form.Item name="feedUrl">
            <Input placeholder="Ссылка на фид, используемый в zoomos" />
          </Form.Item>}

        </Form>
      </Modal>
      <Modal
        title="Редактировать"
        open={modalsVisible.editModalVisible}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={handleEditModalOk}>
            Сохранить изменения
          </Button>,
        ]}
      >
        <Form
          form={formEdit}
          name="editIntegrationForm"
          onFinish={onFinishEdit}
          initialValues={{ remember: true }}
          autoComplete="off"
          style={{ marginTop: 20 }}
        >

          <Form.Item
            name="name"
            shouldUpdate
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название интеграции!",
              },
            ]}
          >
            <Input placeholder="Название интеграции" />
          </Form.Item>

          {clickedRow?.type === 1 &&  <> <Form.Item
            name="token"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите API токен аккаунта!",
              },
            ]}
          >
            <Input placeholder={clickedRow.type === 1?"API токен аккаунта":"Ссылка на публичную таблицу в Google Sheets"} />
          </Form.Item><Form.Item name="feedUrl">
            <Input placeholder="Ссылка на фид, используемый в zoomos" />
          </Form.Item></>}
          {clickedRow?.type === 2 &&  <> <Form.Item
            name="url"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите URL!",
              },
            ]}
          >
            <Input placeholder={clickedRow?.type === 1?"API токен аккаунта":"Ссылка на публичную таблицу в Google Sheets"} />
          </Form.Item></>}
        </Form>
      </Modal>

      <DeleteModal
        onConfirmDelete={handleDeleteModalOk}
        isModalDeleteOpen={modalsVisible.deleteModalVisible}
        setIsModalDeleteOpen={setDeleteModalVisible}
        title="Удалить интеграцию"
        text={`Вы уверены, что хотите удалить "${clickedRow?.name}" со всем содержимым?`}
      />
    </>
  );
};

export default ModalsIntegration;

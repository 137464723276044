const Logo = () => {
    return (
        <div className="logo logo-layout">
        <a href="/" style={{ display: "flex" }}>
          {" "}
          <img src="/logo.png" alt="" />
        </a>
      </div>
        );
}
export default Logo;
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Flex, Form, Input } from "antd";

import { Context } from "../index";
import { useContext, useEffect } from "react";
import Logo from "../components/Layout/Header/Logo";

const onFinishFailed = (errorInfo) => {
  console.error("Failed:", errorInfo);
};

const Login = ({ isAuth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (isAuth) {
      navigate("/", { replace: true });
    }
// eslint-disable-next-line
  }, [isAuth]);

  const from = location.state?.from?.pathname || "/";
  const { store } = useContext(Context);

  return (
    <div
      style={{
        background: "#F1F1F2",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Flex gap="middle" align="center" justify="center" vertical>
        <Logo />
        <div className="authcard">
          <h2>Авторизация</h2>
          {isAuth ? (
            <>
              <div>Вы уже вошли</div>
              <button onClick={() => store.logout()}>Выйти</button>
            </>
          ) : (
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              layout="vertical"
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={(fields) => {
                store.login(fields.username, fields.password);
                navigate(from, { replace: true });
              }}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Ваш логин"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Укажите логин",
                  },
                ]}
              >
                <Input placeholder="Логин" />
              </Form.Item>

          
              <Form.Item
                label="Пароль"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Укажите пароль",
                  },
                ]}
              >
                <Input.Password placeholder="Пароль" 
                
                />
              </Form.Item>
             
         
            
                <Form.Item className="loginform_passwordfield">
                <Link to="/forgot-password">Не помню пароль</Link>
              </Form.Item>

              <Form.Item>
                <Button size="large" block type="primary" htmlType="submit">
                  Войти
                </Button>
              </Form.Item>
            </Form>
          )}

          <div className="loginform-regtext">
            У вас нет учетной записи? {" "}
            <Link to="/registration">Зарегистрироваться</Link>
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default Login;

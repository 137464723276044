import React, { useState } from "react";
import { Flex, Popover, Modal, Input } from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined,ShareAltOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../ui/Modals/DeleteModal";

const SidebarMenuParent = ({ title, id, deleteProject,updateProject }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const navigate = useNavigate();
  const handleMoreBtnClick = (e) => {
    e.stopPropagation();
    setPopoverVisible(!popoverVisible);
  };

  const handleParentEdit = (e) => {
    e.stopPropagation();
    setEditModalVisible(true);
    setPopoverVisible(false);
  };

  const handleParentDelete = (e) => {
    e.stopPropagation();
    setDeleteModalVisible(true);
    setPopoverVisible(false);
  };
  const handleParentIntegrations = (e) => {
    e.stopPropagation();
    // setDeleteModalVisible(true);
    navigate(`/integrations/${id}`);
    setPopoverVisible(false);
  };

  const handlePopoverClose = () => {
    setPopoverVisible(false);
  };

  const handleEditModalOk = (e) => {
    e.stopPropagation();

    updateProject(id,newTitle)

    // Здесь может быть логика сохранения нового названия
    setEditModalVisible(false);
  };

  const handleEditModalCancel = (e) => {
    e.stopPropagation();
    setEditModalVisible(false);
  };

  const handleDeleteModalOk = (e) => {
    e.stopPropagation();
    // Здесь может быть логика удаления элемента

    deleteProject(id);

    setDeleteModalVisible(false);

    navigate("/");
  };




  const content = (
    <ul className="sidebar_parentmenu-list">
      <li onClick={handleParentIntegrations}>
        <ShareAltOutlined />
        <span>Интеграции проекта</span>
      </li>
      <li onClick={handleParentEdit}>
        <EditOutlined />
        <span>Переименовать</span>
      </li>
      <li onClick={handleParentDelete}>
        <DeleteOutlined />
        <span>Удалить</span>
      </li>
    </ul>
  );
  return (
    <div >
      <Popover
        content={content}
        placement="right"
        trigger="click"
        onOpenChange={handlePopoverClose}
        open={popoverVisible}
      >
        <Flex justify="space-between" align="center">
          <span className="submenu_title">{title}</span>
          <button onClick={handleMoreBtnClick} className="submenu_morebtn">
            <MoreOutlined style={{ fontSize: "16px", color: "#111" }} />
          </button>
        </Flex>
      </Popover>
      <Modal
        title="Редактировать"
        open={editModalVisible}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}

      >
        <Input
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          onClick={(e) => e.stopPropagation()} // Предотвращение всплытия при клике на поле ввода
        />
      </Modal>

      <DeleteModal
        onConfirmDelete={handleDeleteModalOk}
        isModalDeleteOpen={deleteModalVisible}
        setIsModalDeleteOpen={setDeleteModalVisible}
        title='Удалить проект'
        text={`Вы уверены, что хотите удалить "${title}" со всем содержимым?`}
      />
    </div>
  );
};

export default SidebarMenuParent;

import { Button } from "antd";
import React, { useState } from "react";
export default function FeedDownloadXLSX({ tableid, download }) {
//   const [downloading, setDownloading] = useState(false);

//   const downloadXLSX = async () => {
//     setDownloading(true);
//     await download(tableid);
//     setDownloading(false);
//   };

  return (
    <div>
      {/* <Button
        size="large"
        onClick={downloadXLSX}
        type="primary"
        loading={downloading}
        style={{ marginBottom: 10 }}
      >
        Сохранить в XLSX
      </Button> */}

      <a target="_blank" className="btn" style={{height: 50}} download={`${tableid}.xlsx`} href={`https://feedcenter.ru/api/feeds/${tableid}/xlsx`}>Сохранить в XLSX</a>

    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Input, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Context } from "../";
import { observer } from "mobx-react-lite";
const Main = () => {
  const { store } = useContext(Context);

  // useEffect(() => {
  //   const prepareProjects = (items) => {
  //     let trst = [];
  //     if (items.length > 0) {
  //       trst = items.map((item) => {
  //         let tables = [];
  //         })
  //     }
  //     return trst;
  //   };
  //   setProjects(prepareProjects(store.projects));
  //   setForceUpdate((prev) => prev + 1);
  // }, [store.projects]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    AddProject();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [loading, setLoading] = useState(false);

  const addNewProject = (newProjectName) => {
    return store.addProject(newProjectName);
  };
  const [newProjectName, setNewProjectName] = useState("");

  const AddProject = async () => {
    setLoading(true);
    await addNewProject(newProjectName).then((resp) => {
      if (resp) {
        setNewProjectName("");
      }
    });
    setLoading(false);
  };

  const [isShowNoProjects, setIsShowNoProjects] = useState(false);
  useEffect(() => {
    if(store.projects.length){
      setIsShowNoProjects(false);
    }else{
      setIsShowNoProjects(true);
    }


  }, [store.projects]);


  return (
    <>
      <h1>Мои проекты</h1>
      <Col span={10}>
        <div className="attention mainPageNoProjects">

          {isShowNoProjects && (  <div style={{ marginBottom: 5 }}>
            У вас еще нет ни одного проекта
          </div>)}


          <button
            onClick={showModal}
            className="myProjectsButton"
            style={{ marginLeft: "20px", marginTop: 10 }}
          >
            <span className="myProjectsButton-text">Добавить проект</span>{" "}
            <PlusOutlined style={{ fontSize: "18px" }} />
          </button>
        </div>
      </Col>
      <Modal
        title="Создать проект"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Создать проект
          </Button>,
        ]}
      >
        <Input
          placeholder="Название проекта"
          value={newProjectName}
          onChange={(e) => {
            setNewProjectName(e.target.value);
          }}
        />
      </Modal>
    </>
  );
};

export default observer(Main);

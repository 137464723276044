import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../index";
function Logout() {
  const navigate = useNavigate();
  
  const { store } = useContext(Context);

  useEffect(() => {
    store.logout()
    navigate('/login');
  }, [store, navigate])

  return (
    <div>Logout</div>
  )
}

export default Logout
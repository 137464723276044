import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import Store from "./store/store";


export const store = new Store();

export const Context = createContext({
  store,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Context.Provider
      value={{
        store,
      }}
    >
      

      <App />
    
    </Context.Provider>
  </Router>
);